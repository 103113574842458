<template>

    <div>

        <!-- gotocookies -->
        <b-modal
            v-model="cookieModal"
            body-class="cookie-modal"
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            hide-header
            aria-label="Cookie Modal"
            title="Cookie Modal"
        >
            <div id="cookie-text">
                Cookies are used on this site to remember your settings. Cookies are small text files stored in your browser or computer.
            </div>

            <div id="cookie-buttons">
                <b-link id="cookie-close" @click="rejectCookies(); cookieModal=false">
                    <b-icon icon="x-square" title="Reject Cookies" />
                </b-link>
                <b-button  class="mt-2 cookie-button" block @click="acceptCookies(); cookieModal=false">
                    Accept
                </b-button>
            </div>
        </b-modal>

        <!-- gotoscreenflash -->
        <div id="fullscreen"></div>

        <!-- gotonav -->
        <div class="utility-header" ref="utilityHeader">
            <div class="utility-container">
                <div class="flex-row">
                    <div class="social-media-links">
                        <div class="header-cagov-logo">
                        <a href="https://ca.gov">
                            <span class="sr-only">CA.gov</span>
                            <span class="ca-gov-logo-svg"></span>
                        </a>
                        </div>
                        <p class="official-tag">
                        <span class="desktop-only">Official website of the</span>
                        State of California
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <b-navbar type="light" variant="light" sticky fixed="top" class="p-0 consistent-line-height" ref="appBar">
            <div class="logo-container">
                <b-navbar-brand target="_blank" href="https://earthquake.ca.gov/">
                    <img alt="caloes logo" src="img/caloes_transparent.png" class="logo">
                </b-navbar-brand>
                <b-navbar-brand target="_blank" href="https://www.conservation.ca.gov/cgs/">
                    <img alt="cgs logo" src="img/CGSLockup-DOC-Stacked-FullColor-transparent-bkg.png" id="CGS-logo" class="logo">
                </b-navbar-brand>
            </div>

            <div class="title-container">
                <h1 class="title">Quake Display</h1>
            </div>


            <b-navbar-nav class="ml-auto">
                <template v-if="isAuthenticated">
                    <b-nav-item class="authenticated-container">
                        <b-icon v-if="signalrState === 'Connected'"
                            scale="0.25" icon="circle-fill" :title="signalrState" class="green-color" />
                        <b-icon v-else-if="signalrState === 'Disconnected'"
                            scale="0.25" icon="circle-fill" :title="signalrState" class="red-color" />
                        <b-icon v-else
                            scale="0.25" icon="circle-fill" :title="signalrState" class="orange-color" />
                    </b-nav-item>
                </template>

                <b-nav-item @click="toggleEvent()" :active="showEvent">
                    <b-icon font-scale="1.5" icon="soundwave" title="Details" />
                </b-nav-item>
                <b-nav-item @click="toggleMap()" :active="showMap">
                    <b-icon font-scale="1.5" icon="globe" title="Map" />
                </b-nav-item>
                <b-nav-item @click="toggleList()" :active="showList">
                    <b-icon font-scale="1.5" icon="list-ul" title="List" />
                </b-nav-item>
                <b-nav-item @click="toggleSettings()" :active="showSettings">
                    <b-icon font-scale="1.5" icon="gear" title="Settings" />
                </b-nav-item>
                <b-nav-item @click="toggleHelp()" :active="showHelp">
                    <b-icon font-scale="1.5" icon="question-circle" title="Help" />
                </b-nav-item>

            </b-navbar-nav>

        </b-navbar>

        <b-container fluid class="p-0">
        <b-row no-gutters class="gx-0">

            <!-- gotomap -->
            <b-col  v-show="showMap" order="2" class="flexed" v-bind:style="{height: mapHeight + 'px'}">

                <l-map
                    ref="map"
                    :zoom="zoom"
                    :center="center"
                    :minZoom="minZoom"
                    zoomDelta=0.5
                    :worldCopyJump="true"
                    :scrollWheelZoom="false"
                    @moveend="updateMapBounds()"
                    @update:center="setCenterCookie"
                    @update:zoom="setZoomCookie"
                    :options="{ zoomDelta: 1.0, zoomSnap: 1.0, preferCanvas: true }"
                >

                    <l-control-scale
                        position="bottomleft"
                        :imperial="true"
                        :metric="true">
                    </l-control-scale>

                    <l-control position="topright" class="position-relative">
                        <b-dropdown
                            role="navigation"
                            id="mapLayers"
                            ref="mapLayers"
                            variant="light"
                            size="sm"
                            right
                            no-caret
                            dropleft
                            menu-class="dropdown-content">

                            <template #button-content>
                                <b-icon font-scale="1.5" icon="layers" title="Layers" />
                            </template>

                            <b-dropdown-header>Base Layers</b-dropdown-header>

                            <b-dropdown-form>
                            <b-form-group id="baseLayers" label="Base Layers" label-sr-only class="mb-0">
                                <b-form-radio-group
                                    v-model="layerIndex"
                                    :options="baseLayers"
                                    value-field="item"
                                    text-field="name"
                                    stacked
                                    class=""
                                    size="sm"
                                    @change="changeBaseLayer(layerIndex, $event)"
                                ></b-form-radio-group>
                            </b-form-group>
                            </b-dropdown-form>

                            <b-dropdown-header>Overlays</b-dropdown-header>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Earthquakes</div>
                                <b-form-checkbox v-model="showEarthquakes" name="showEarthquakes" switch  size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">U.S. Volcanoes</div>
                                <b-form-checkbox v-model="showVolcanoes" name="showVolcanoes" switch  size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">U.S. Faults</div>
                                <b-form-checkbox v-model="showUsFaults" name="showUsFaults" switch  size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Plate Boundaries</div>
                                <b-form-checkbox v-model="showPlateBoundaries" name="showPlateBoundaries" switch  size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">U.S. States</div>
                                <b-form-checkbox v-model="showUsStates" name="showUsStates" switch size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">U.S. Counties</div>
                                <b-form-checkbox v-model="showUsCounties" name="showUsCounties" switch size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Labels/Roads</div>
                                <b-form-checkbox v-model="showLabels" name="showLabels" switch size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Traffic/Flow</div>
                                <b-form-checkbox v-model="showTrafficFlow" name="showTrafficFlow" switch size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Traffic/Incident</div>
                                <b-form-checkbox v-model="showTrafficIncident" name="showTrafficIncident" switch size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Weather/Infrared</div>
                                <b-form-checkbox v-model="showInfrared" name="showInfrared" switch size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Weather/Radar</div>
                                <b-form-checkbox v-model="showWeather" name="showWeather" switch size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Classic colors</div>
                                <b-form-checkbox v-model="showClassicColors" name="showClassicColors" switch  size="sm" />
                            </b-dropdown-form>

                            <b-dropdown-form class="switch-container">
                                <div class="text-nowrap">Square markers</div>
                                <b-form-checkbox v-model="showSquareMarkers" name="showSquareMarkers" switch  size="sm" />
                            </b-dropdown-form>

                        </b-dropdown>
                    </l-control>

                    <l-control position="topright" class="position-relative">
                        <b-dropdown
                            role="navigation"
                            id="mapShakemaps"
                            ref="mapShakemaps"
                            variant="light"
                            size="sm"
                            no-caret
                            right
                            dropleft
                            menu-class="dropdown-content"
                        >

                            <template #button-content>
                                <b-icon font-scale="1.5" icon="bullseye" title="ShakeMaps" />
                            </template>

                            <b-dropdown-header class="float-right">
                                ShakeMap Overlays
                                <b-link @click="removeShakemaps" title="Remove All ShakeMaps">
                                    <b-icon icon="x-square" title="Remove All ShakeMaps" />
                                </b-link>
                            </b-dropdown-header>

                            <template v-for="shakemap in shakemaps">

                                <b-dropdown-header class="float-right">
                                    <b-link @click="panToFeature(shakemap.feature)" title="Zoom to Event">
                                        M {{ formatMag(shakemap.feature.properties.mag) }} - {{ formatEventID(shakemap.feature.id) }}
                                    </b-link>
                                    <b-link @click="shakemapManager(shakemap.feature, false)" title="Remove This ShakeMap">
                                        <b-icon icon="x-square" title="Remove This ShakeMap" />
                                    </b-link>
                                </b-dropdown-header>

                                <b-dropdown-form>
                                    <div class='switch-container'>
                                        <div class="text-nowrap">MMI Contours</div>
                                        <b-form-checkbox
                                            name="shakemapShapefile"
                                            :checked="shakemap['shapefile']['checked']"
                                            @change="toggleShakemap(shakemap.feature, 'shapefile', $event)"
                                            size="sm"
                                            switch
                                        />
                                    </div>

                                    <div class="switch-container">
                                        <div class="text-nowrap">Intensity Overlay</div>
                                        <b-form-checkbox
                                            name="shakemapIntensity"
                                            :checked="shakemap['intensity']['checked']"
                                            @change="toggleShakemap(shakemap.feature, 'intensity', $event)"
                                            size="sm"
                                            switch
                                        />
                                    </div>

                                    <div class="switch-container">
                                        <div class="text-nowrap">Stations</div>
                                        <b-form-checkbox
                                            name="shakemapStations"
                                            :checked="shakemap['stations']['checked']"
                                            @change="toggleShakemap(shakemap.feature, 'stations', $event)"
                                            size="sm"
                                            switch
                                        />
                                    </div>
                                </b-dropdown-form>

                            </template>

                        </b-dropdown>
                    </l-control>

                    <l-control position="topright" class="position-relative">
                        <b-dropdown
                            role="navigation"
                            id="mapPresets"
                            ref="mapPresets"
                            variant="light"
                            size="sm"
                            right
                            no-caret
                            dropleft
                            menu-class="dropdown-content">

                            <template #button-content>
                                <b-icon font-scale="1.5" icon="bookmarks" title="Regions" />
                            </template>

                            <b-dropdown-header>Regions</b-dropdown-header>

                            <b-dropdown-item-button
                                v-for="(preset, index) in presets"
                                :key="preset.index"
                                :value="preset.value"
                                @click="mapPresetClicked(preset.value)"
                                class=""
                            >
                                {{ preset.text }}
                            </b-dropdown-item-button>
                        </b-dropdown>
                    </l-control>

                    <l-control position="topright" class="position-relative">
                        <b-dropdown
                            role="navigation"
                            id="mapLegend"
                            ref="mapLegend"
                            variant="light"
                            size="sm"
                            right
                            no-caret
                            dropleft
                            menu-class="dropdown-content legend" >

                            <template #button-content>
                                <b-icon font-scale="1.5" icon="key" title="Legend" />
                            </template>

                            <b-dropdown-header class="text-center">Magnitude</b-dropdown-header>
                            <b-dropdown-text>
                                <b-list-group horizontal class="d-flex justify-content-center align-items-end">
                                    <b-list-group-item class="border-0 transparent">
                                        0
                                    </b-list-group-item>
                                    <template v-for="n in 8">
                                        <b-list-group-item class="border-0 transparent px-2">
                                            <div :style="getFeatureStyle(n-1)" class="transparent-icon"></div>
                                        </b-list-group-item>
                                    </template>
                                    <b-list-group-item class="border-0 transparent">
                                        7+
                                    </b-list-group-item>
                                </b-list-group>
                            </b-dropdown-text>

                            <b-dropdown-header class="text-center">Age</b-dropdown-header>
                            <b-dropdown-text>
                                <b-list-group horizontal class="d-flex justify-content-center">
                                    <b-list-group-item class="border-0 transparent">
                                        <div :style="getFeatureStyle(5)" 
                                             :class="[markerDecoration == 'highlight' ? 'selected-icon' : 'transparent-icon', 
                                             markerDecoration == 'bold' ? 'selected-border':'',
                                             markerDecoration == 'cross' ? 'legend-cross-background':''    
                                             ]">

                                        </div>
                                        <div class="text-center">Selected</div>
                                    </b-list-group-item>
                                    <b-list-group-item class="border-0 transparent">
                                        <div :style="getFeatureStyle(5)" class="red-icon"></div>
                                        <div class="text-center">Hour</div>
                                    </b-list-group-item>
                                    <b-list-group-item class="border-0 transparent">
                                        <div
                                            :style="getFeatureStyle(5)"
                                            :class="showClassicColors ? 'blue-icon' : 'orange-icon'"
                                        ></div>
                                        <div class="text-center">Day</div>
                                    </b-list-group-item>
                                    <b-list-group-item class="border-0 transparent">
                                        <div :style="getFeatureStyle(5)" class="yellow-icon"></div>
                                        <div class="text-center">Week</div>
                                    </b-list-group-item>
                                    <b-list-group-item class="border-0 transparent">
                                        <div :style="getFeatureStyle(5)" class="transparent-icon"></div>
                                        <div class="text-center">Older</div>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-dropdown-text>

                            <template v-if="containsAnyOptionalOverlays">
                                <b-dropdown-header class="text-center">Overlays</b-dropdown-header>
                                <b-dropdown-text>
                                    <ul class="overlay-legend">
                                        <li>U.S. Counties <div :style="usCountiesStyle()"></div></li>
                                        <li>U.S. States <div :style="usStatesStyle()"></div></li>
                                        <li>U.S. Faults <div :style="usFaultsStyle()"></div></li>
                                        <li>Plate Boundaries <div :style="plateBoundariesStyle()"></div></li>
                                    </ul>
                                </b-dropdown-text>
                            </template>

                            <template v-if="shakemaps.length > 0">
                                <b-dropdown-header class="text-center">ShakeMap Intensity</b-dropdown-header>
                                <b-dropdown-text>
                                    <b-list-group horizontal class="d-flex justify-content-center">
                                        <img src="img/shakemap-intensity-legend-small.png" width=300>
                                    </b-list-group>
                                    <ul class="overlay-legend">
                                        <li>
                                            Seismic Station 
                                            <svg width="12" height="12">
                                                <rect width="8" height="8" x="2" y="2" stroke="black" stroke-opacity="0.5" fill="none" fill-opacity="0.5" transform="rotate(45, 6, 6)" />
                                            </svg>
                                        </li>
                                        <li>
                                            DYFI ShakeMap Station 
                                            <svg width="12" height="12">
                                                <polygon points="5.87,1.276 10.8,4.716 9.0,10.8 2.94,10.8 1.276,4.716" stroke="black" stroke-opacity="0.5" fill="none" fill-opacity="0.5"/>
                                            </svg>
                                        </li>
                                    </ul>
                                </b-dropdown-text>
                            </template>

                            <template v-if="showVolcanoes">
                                <b-dropdown-header class="text-center">U.S. Volcanoes</b-dropdown-header>
                                <b-dropdown-text>
                                    <b-list-group horizontal class="d-flex justify-content-center">
                                        <img src="img/volcano_legend.jpg" width=300>
                                    </b-list-group>
                                </b-dropdown-text>
                            </template>

                        </b-dropdown>
                    </l-control>


                    <!-- NOTE: for each of the EQ marker sections, there are three instances, center, left, right,
                               which create three copies on the leaflet map to display a 'continuous world'.
                               each section can be tested with or without the three instances,
                               by commenting out the 'left' and 'right' instances.
                    -->

                    <!-- BEGIN vue2leaflet circlemarkers -->
                    <template v-if="markerStyleOption === 'circlemarkers'">
                      <l-circle-marker
                          v-for="(feature, layer) in filter1"
                          :visible="showEarthquakes"
                          :key="feature.id"
                          :name="feature.id"
                          :lat-lng="getFeatureCoords(feature, '')"
                          :radius="getFeatureSize(feature.properties.mag) / 2"
                          :color="getCircleMarkerColor(feature)"
                          :opacity="0.5"
                          :fillColor="getCircleMarkerFillColor(feature)"
                          :fillOpacity="1.0"
                          @click="mapFeatureClicked(feature, layer)"
                          @mouseenter="setHoveredFeature(feature)" 
                          @mouseleave="setHoveredFeature(null)"
                      >
                          <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity, permanent: false, sticky: false, }">
                              <div>
                                  {{ formatDate(feature.properties.time) }} <br>
                                  Event ID: {{ formatEventID(feature.id) }} <br>
                                  Status: {{ formatStatus(feature.properties.status) }} <br>
                                  Depth: {{ formatDepth(feature.geometry.coordinates[2]) }} <br>
                                  Mag: {{ formatMag(feature.properties.mag) }}{{ feature.properties.magType }} <br>
                                  Type: {{ formatType(feature.properties.type) }}
                              </div>
                          </l-tooltip>
                      </l-circle-marker>
                      <template v-if="wrappedMarkers">
                        <l-circle-marker
                            v-for="(feature, layer) in filter1"
                            :visible="showEarthquakes"
                            :key="'l-' + feature.id"
                            :name="'l-' + feature.id"
                            :lat-lng="getFeatureCoords(feature, 'left')"
                            :radius="getFeatureSize(feature.properties.mag) / 2"
                            :color="getCircleMarkerColor(feature)"
                            :opacity="0.5"
                            :fillColor="getCircleMarkerFillColor(feature)"
                            :fillOpacity="1.0"
                            @click="mapFeatureClicked(feature, layer)"
                            @mouseenter="setHoveredFeature(feature)" 
                            @mouseleave="setHoveredFeature(null)"
                        >
                            <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity, permanent: false, sticky: false, }">
                                <div>
                                    {{ formatDate(feature.properties.time) }} <br>
                                    Event ID: {{ formatEventID(feature.id) }} <br>
                                    Status: {{ formatStatus(feature.properties.status) }} <br>
                                    Depth: {{ formatDepth(feature.geometry.coordinates[2]) }} <br>
                                    Mag: {{ formatMag(feature.properties.mag) }}{{ feature.properties.magType }} <br>
                                    Type: {{ formatType(feature.properties.type) }}
                                </div>
                            </l-tooltip>
                        </l-circle-marker>
                        <l-circle-marker
                            v-for="(feature, layer) in filter1"
                            :visible="showEarthquakes"
                            :key="'r-' + feature.id"
                            :name="'r-' + feature.id"
                            :lat-lng="getFeatureCoords(feature, 'right')"
                            :radius="getFeatureSize(feature.properties.mag) / 2"
                            :color="getCircleMarkerColor(feature)"
                            :opacity="0.5"
                            :fillColor="getCircleMarkerFillColor(feature)"
                            :fillOpacity="1.0"
                            @click="mapFeatureClicked(feature, layer)"
                            @mouseenter="setHoveredFeature(feature)" 
                            @mouseleave="setHoveredFeature(null)"
                        >
                            <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity, permanent: false, sticky: false, }">
                                <div>
                                    {{ formatDate(feature.properties.time) }} <br>
                                    Event ID: {{ formatEventID(feature.id) }} <br>
                                    Status: {{ formatStatus(feature.properties.status) }} <br>
                                    Depth: {{ formatDepth(feature.geometry.coordinates[2]) }} <br>
                                    Mag: {{ formatMag(feature.properties.mag) }}{{ feature.properties.magType }} <br>
                                    Type: {{ formatType(feature.properties.type) }}
                                </div>
                            </l-tooltip>
                        </l-circle-marker>
                      </template>
                    </template>

                    <!-- BEGIN vue2leaflet markers -->
                    <template v-else-if="markerStyleOption === 'markers'">
                      <l-marker
                          v-for="(feature, layer) in filter1"
                          :visible="showEarthquakes"
                          :key="feature.id"
                          :name="feature.id"
                          :lat-lng="getFeatureCoords(feature, '')"
                          :icon="getFeatureIcon(feature)"
                          :z-index-offset="getFeatureZIndex(feature)"
                          @click="mapFeatureClicked(feature, layer)"
                          @mouseenter="setHoveredFeature(feature)" 
                          @mouseleave="setHoveredFeature(null)"
                          >
                          <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity }">
                              <div>
                                  {{ formatDate(feature.properties.time) }} <br>
                                  Event ID: {{ formatEventID(feature.id) }} <br>
                                  Status: {{ formatStatus(feature.properties.status) }} <br>
                                  Depth: {{ formatDepth(feature.geometry.coordinates[2]) }} <br>
                                  Mag: {{ formatMag(feature.properties.mag) }}{{ feature.properties.magType }} <br>
                                  Type: {{ formatType(feature.properties.type) }}
                              </div>
                          </l-tooltip>
                      </l-marker>
                      <template v-if="wrappedMarkers">
                        <l-marker
                            v-for="(feature, layer) in filter1"
                            :visible="showEarthquakes"
                            :key="'l-' + feature.id"
                            :name="'l-' + feature.id"
                            :lat-lng="getFeatureCoords(feature, 'left')"
                            :icon="getFeatureIcon(feature)"
                            :z-index-offset="getFeatureZIndex(feature)"
                            @click="mapFeatureClicked(feature, layer)"
                            @mouseenter="setHoveredFeature(feature)" 
                            @mouseleave="setHoveredFeature(null)"
                            >
                            <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity }">
                                <div>
                                    {{ formatDate(feature.properties.time) }} <br>
                                    Event ID: {{ formatEventID(feature.id) }} <br>
                                    Status: {{ formatStatus(feature.properties.status) }} <br>
                                    Depth: {{ formatDepth(feature.geometry.coordinates[2]) }} <br>
                                    Mag: {{ formatMag(feature.properties.mag) }}{{ feature.properties.magType }} <br>
                                    Type: {{ formatType(feature.properties.type) }}
                                </div>
                            </l-tooltip>
                        </l-marker>
                        <l-marker
                            v-for="(feature, layer) in filter1"
                            :visible="showEarthquakes"
                            :key="'r-' + feature.id"
                            :name="'r-' + feature.id"
                            :lat-lng="getFeatureCoords(feature, 'right')"
                            :icon="getFeatureIcon(feature)"
                            :z-index-offset="getFeatureZIndex(feature)"
                            @click="mapFeatureClicked(feature, layer)"
                            @mouseenter="setHoveredFeature(feature)" 
                            @mouseleave="setHoveredFeature(null)"
                            >
                            <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity }">
                                <div>
                                    {{ formatDate(feature.properties.time) }} <br>
                                    Event ID: {{ formatEventID(feature.id) }} <br>
                                    Status: {{ formatStatus(feature.properties.status) }} <br>
                                    Depth: {{ formatDepth(feature.geometry.coordinates[2]) }} <br>
                                    Mag: {{ formatMag(feature.properties.mag) }}{{ feature.properties.magType }} <br>
                                    Type: {{ formatType(feature.properties.type) }}
                                </div>
                            </l-tooltip>
                        </l-marker>

                      </template>
                    </template>

                    <!-- BEGIN vue2leaflet geojson overlays, also with three copies each, for a continuous world -->
                    <l-geo-json
                        :geojson="plateBoundaries"
                        :visible="showPlateBoundaries"
                        :options="plateBoundariesOptions"
                        :options-style="plateBoundariesStyle"
                    />
                    <template v-if="wrappedMarkers">
                      <l-geo-json
                          :geojson="plateBoundaries"
                          :visible="showPlateBoundaries"
                          :options="plateBoundariesOptionsLeft"
                          :options-style="plateBoundariesStyle"
                      />
                      <l-geo-json
                          :geojson="plateBoundaries"
                          :visible="showPlateBoundaries"
                          :options="plateBoundariesOptionsRight"
                          :options-style="plateBoundariesStyle"
                      />
                    </template>

                    <l-geo-json
                        :geojson="usFaults"
                        :visible="showUsFaults"
                        :options="usFaultsOptions"
                        :options-style="usFaultsStyle"
                    />
                    <template v-if="wrappedMarkers">
                      <l-geo-json
                          :geojson="usFaults"
                          :visible="showUsFaults"
                          :options="usFaultsOptionsLeft"
                          :options-style="usFaultsStyle"
                      />
                      <l-geo-json
                          :geojson="usFaults"
                          :visible="showUsFaults"
                          :options="usFaultsOptionsRight"
                          :options-style="usFaultsStyle"
                      />
                    </template>

                    <l-geo-json
                        :geojson="usStates"
                        :visible="showUsStates"
                        :options="usStatesOptions"
                        :options-style="usStatesStyle"
                    />
                    <template v-if="wrappedMarkers">
                      <l-geo-json
                          :geojson="usStates"
                          :visible="showUsStates"
                          :options="usStatesOptionsLeft"
                          :options-style="usStatesStyle"
                      />
                      <l-geo-json
                          :geojson="usStates"
                          :visible="showUsStates"
                          :options="usStatesOptionsRight"
                          :options-style="usStatesStyle"
                      />
                    </template>

                    <l-geo-json
                        :geojson="usCounties"
                        :visible="showUsCounties"
                        :options="usCountiesOptions"
                        :options-style="usCountiesStyle"
                    />
                    <template v-if="wrappedMarkers">
                      <l-geo-json
                          :geojson="usCounties"
                          :visible="showUsCounties"
                          :options="usCountiesOptionsLeft"
                          :options-style="usCountiesStyle"
                      />
                      <l-geo-json
                          :geojson="usCounties"
                          :visible="showUsCounties"
                          :options="usCountiesOptionsRight"
                          :options-style="usCountiesStyle"
                      />
                    </template>

                    <!-- BEGIN vue2leaflet marker overlay for volcanos  -->
                    <l-marker
                        v-for="feature in volcanoes"
                        :visible="showVolcanoes"
                        :key="feature.properties.vnum + '-' + feature.properties.alertLevel"
                        :lat-lng="getFeatureCoords(feature, '')"
                        :icon="getVolcanoIcon(feature)"
                        >
                        <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity }">
                            {{ feature.properties.volcanoName }}
                        </l-tooltip>
                    </l-marker>
                    <template v-if="wrappedMarkers">
                      <l-marker
                          v-for="feature in volcanoes"
                          :visible="showVolcanoes"
                          :key="'l-' + feature.properties.vnum + '-' + feature.properties.alertLevel"
                          :lat-lng="getFeatureCoords(feature, 'left')"
                          :icon="getVolcanoIcon(feature)"
                          >
                          <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity }">
                              {{ feature.properties.volcanoName }}
                          </l-tooltip>
                      </l-marker>
                      <l-marker
                          v-for="feature in volcanoes"
                          :visible="showVolcanoes"
                          :key="'r-' + feature.properties.vnum + '-' + feature.properties.alertLevel"
                          :lat-lng="getFeatureCoords(feature, 'right')"
                          :icon="getVolcanoIcon(feature)"
                          >
                          <l-tooltip :options="{ className: 'tooltip', opacity: tooltipOpacity }">
                              {{ feature.properties.volcanoName }}
                          </l-tooltip>
                      </l-marker>
                    </template>

                </l-map>

            </b-col>

            <!-- gotolist -->
            <b-col  id="list" v-show="showList" order="3" v-bind:style="{height: mapHeight + 'px'}" :class="[showMap ? 'fixed-side' : 'flexed']">

                    <b-navbar type="light" variant="light" class="px-2 py-0">
                        <b-navbar-brand>
                            <b-icon icon="list-ul" title="List" /> {{ filter3Count }} of {{ filter1Count }} Earthquakes
                        </b-navbar-brand>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item @click="toggleList()">
                                <b-icon icon="x-square" title="Close List"/>
                            </b-nav-item>
                        </b-navbar-nav>
                    </b-navbar>

                    <div class="px-2 py-2 switch-container">
                        <div class="text-nowrap">Only List Earthquakes Within Map Bounds</div>
                        <b-form-checkbox v-model="listFollowsMap" name="listFollowsMap" switch/>
                    </div>

                    <div class="px-2 py-2">
                        <b-form-group
                            id="listSortOptionsGroup"
                            label="Sort Options"
                            label-for="listSortOptions"
                            label-cols="auto"
                            class="my-0"
                        >
                        <b-form-select
                            id="listSortOptions"
                            v-model="listSortOption"
                            :options="listSortOptions"
                            @change="listSortChange(listSortOption)"
                            size="sm"
                         >
                         </b-form-select>
                         </b-form-group>
                    </div>

                    <div id="eventTableWrapper" v-bind:style="{height: listHeight + 'px'}">
                        <div id="eventTablePageWrapper">

                        <b-table
                            :items="filter3"
                            :fields="listFields"
                            id="eventList"
                            ref="eventList"
                            primary-key="id"
                            :sort-by="listSortField"
                            :sort-desc="listSortDescending"
                            hover
                            selectable
                            select-mode="single"
                            selected-variant="info"
                            @row-selected="tableRowClicked"
                            :tbody-tr-class="tableRowVariant"
                            :no-border-collapse="true"
                            fixed
                            small
                            :per-page="perPage"
                            :current-page="currentPage"
                            v-model="currentList"
                        >

                            <template 
                                #cell(feature)="data"  
                            >
                                <b-container 
                                    fluid 
                                    class="px-2 m-0"
                                    @mouseenter="setHoveredFeature(data.item)" 
                                    @mouseleave="setHoveredFeature(null)"
                                >
                                    <b-row no-gutters class="gx-0" align-v="center" >
                                        <b-col>
                                            <h5 class="pr-3 m-0 text-center">
                                                {{ formatMag(data.item.properties.mag) }}
                                            </h5>
                                        </b-col>
                                        <b-col class="p-0 m-0 text-center">
                                            <b-row no-gutters class="gx-0">
                                                <b-col>
                                                <div class="small" :class="(toProductTypesArray(data.item.properties.types).includes('shakemap'))?'visible':'invisible'">
                                                    S
                                                </div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters class="gx-0">
                                                <b-col>
                                                <div class="small" :class="(data.item.properties.tsunami === 1)?'visible':'invisible'">
                                                    T
                                                </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col cols="10">
                                            <b-row no-gutters class="gx-0">
                                                <b-col>
                                                    <div class="text-truncate">
                                                        {{ formatPlace(data.item.properties.place) }}
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters class="gx-0">
                                                <b-col cols="9">
                                                    <div class="text-truncate small">
                                                        {{ formatDate(data.item.properties.time) }}
                                                    </div>
                                                </b-col>
                                                <b-col cols="3">
                                                    <div class="text-right small">
                                                        {{ formatDistance(data.item.geometry.coordinates[2]) }}
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </template>

                        </b-table>

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="filter3Count"
                            :per-page="perPage"
                            first-text="First"
                            prev-text="Prev"
                            next-text="Next"
                            last-text="Last"
                            first-number
                            last-number
                            size="sm"
                            align="fill"
                            aria-controls="eventList"
                        >
                        </b-pagination>

                        </div>
                    </div>

            </b-col>

            <!-- gotodetail -->
            <b-col id="eventDetail" v-show="showEvent" order="1" v-bind:style="{height: mapHeight + 'px'}" :class="[showMap ? 'fixed-side' : 'flexed']">

                <template v-if="Object.keys(selectedFeature).length === 0" >

                    <b-navbar type="light" variant="light" class="px-2 py-0">
                        <b-navbar-brand class="text-truncate">
                            <b-icon icon="soundwave" title="Event" />
                            No event selected
                        </b-navbar-brand>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item @click="toggleEvent()">
                                <b-icon icon="x-square" title="Close Event" />
                            </b-nav-item>
                        </b-navbar-nav>
                    </b-navbar>

                </template>

                <template v-if="Object.keys(selectedFeature).length > 0" >

                <b-navbar type="light" variant="light" class="px-2 py-0">
                    <b-navbar-brand class="text-truncate">
                        <b-icon icon="soundwave" title="Event" />
                        M {{ formatMag(selectedFeature.properties.mag) }} - {{ formatPlace(selectedFeature.properties.place) }}
                    </b-navbar-brand>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item @click="toggleEvent()">
                            <b-icon icon="x-square" title="Close Event" />
                        </b-nav-item>
                    </b-navbar-nav>
                </b-navbar>

                <b-overlay
                    id="detailOverlay"
                    :show="showDetailOverlay"
                    variant="transparent"
                    opacity="0.5"
                    blur="0.5em"
                    rounded="sm"
                >

                <b-tabs v-model="tabIndex" card>

                        <b-tab title="Event Details" v-bind:style="{height: detailHeight + 'px'}">

                            <b-container class="px-0 event-info-wrapper">
                                <b-table-simple class="event-info-table" responsive>
                                      <b-tbody >
                                        <b-tr>
                                          <b-td > Date </b-td>
                                          <b-td>{{ formatDate(selectedFeature.properties.time) }}</b-td>
                                        </b-tr>
                                        <b-tr class="oneLineRow">
                                          <b-td > Place </b-td>
                                          <b-td  class="text-truncate" >{{formatPlace(selectedFeature.properties.place)}}</b-td>
                                        </b-tr>
                                        <b-tr>
                                          <b-td > Mag </b-td>
                                          <b-td >{{ formatMag(selectedFeature.properties.mag) }}{{ selectedFeature.properties.magType }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                          <b-td > Location </b-td>
                                          <b-td  ><b-link @click="panToFeature(selectedFeature)" v-b-tooltip.hover.right="'Zoom to Event'" >
                                          {{formatLat(selectedFeature.geometry.coordinates[1])}}, {{formatLon(selectedFeature.geometry.coordinates[0])}}
                                          </b-link></b-td>
                                        </b-tr>
                                        <b-tr>
                                          <b-td > Depth </b-td>
                                          <b-td >{{ formatDepth(selectedFeature.geometry.coordinates[2]) }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                          <b-td > Type </b-td>
                                          <b-td >{{ formatType(selectedFeature.properties.type) }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                          <b-td > Event ID </b-td>
                                          <b-td >{{ formatEventID(selectedFeature.id) }}</b-td>
                                        </b-tr>
                                        <b-tr class="oneLineRow">
                                          <b-td > Source </b-td>
                                          <b-td class="text-truncate">{{ selectedFeatureNetworkName }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                          <b-td > Status </b-td>
                                          <b-td >{{ formatStatus(selectedFeature.properties.status) }}</b-td>
                                        </b-tr>
                                      </b-tbody>
                                 </b-table-simple>

                            </b-container>

                            <div class='switch-container'>
                                <div>Add This ShakeMap as Map Overlay</div>
                                <b-form-checkbox switch name="shakemap" :disabled="!toProductTypesArray(selectedFeature.properties.types).includes('shakemap')" :checked="shakemapExists(selectedFeature)" @change="shakemapManager(selectedFeature, $event)"/>
                            </div>

                            <hr>
                            Event Links
                            <ul>
                             <li><b-link :href="selectedFeatureUSGSUrl" target="_blank"> USGS Event Page </b-link></li>
                             <li><b-link :href="selectedFeatureCESMDUrl" target="_blank"> CESMD Event Page </b-link></li>
                            </ul>

                            <hr>
                            Partner Links
                            <ul>
                              <li><b-link href="https://www.tsunami.gov/" target="_blank"> Tsunami Alerts </b-link></li>
                              <li><b-link href="https://scedc.caltech.edu/" target="_blank"> SCEDC Recent Earthquakes </b-link></li>
                              <li><b-link href="https://seismo.berkeley.edu/seismo.real.time.map.html" target="_blank"> UC Berkeley Recent Events</b-link></li>
                              <li><b-link href="https://maps.conservation.ca.gov/cgs/historicearthquakes/" target="_blank"> California Historical Earthquake Information</b-link></li>
                            </ul>

                            <hr>
                            Updated: {{ formatDate(selectedFeature.properties.updated) }}

                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                ShakeMap
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('shakemap') || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else>
                                <b-table-simple id="shakemapTable" responsive class="table">
                                      <b-thead>
                                        <b-tr>
                                          <b-th scope="col">Catalog</b-th>
                                          <b-th scope="col">MMI</b-th>
                                          <b-th scope="col">Description</b-th>
                                          <b-th scope="col">Weight</b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody >
                                        <b-tr class="detail-table-rows" v-for="(shakemap, index) in sortShakemaps" :key="index" :class="{ 'table-info': index === 0 }" v-on:click="changeSelectedShakemap(index,'shakemaps'); changeSelectedRow(index, 'shakemapTable');">
                                          <b-td >{{ shakemap.source.toUpperCase() }}</b-td>
                                          <b-td >{{ formatMMI( shakemap["properties"]["maxmmi"] ) }}</b-td>
                                          <b-td >{{ shakemap["properties"]["event-description"] }}</b-td>
                                          <b-td >{{ shakemap["preferredWeight"] }}</b-td>
                                        </b-tr>
                                      </b-tbody>
                                 </b-table-simple>
                                <b-carousel id="shakemaps" ref="shakemaps" v-model="selectedShakemap" :interval="0" fade>
                                     <b-carousel-slide
                                        v-for="(shakemap,index) in sortShakemaps"
                                        :img-src="shakemap['contents']['download/intensity.jpg']['url']"
                                        :id="shakemap['indexid']"
                                        :key="index"
                                    >
                                    </b-carousel-slide>
                                </b-carousel>
                            </template>
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                DYFI
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('dyfi') || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else>
                                <b-table-simple id="dyfiTable" responsive class="table">
                                    <b-thead>
                                        <b-tr>
                                          <b-th scope="col" >Catalog</b-th>
                                          <b-th scope="col">CDI</b-th>
                                          <b-th scope="col">Responses</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody >
                                        <b-tr class="detail-table-rows" v-for="(dyfi, index) in sortDYFI" :key="dyfi.indexid" :class="{ 'table-info': index === 0 }" v-on:click="changeAllowedDYFI(index,'dyfis'); changeSelectedRow(index, 'dyfiTable');">
                                          <b-td >{{ dyfi.source.toUpperCase() }}</b-td>
                                          <b-td >{{ formatMMI( dyfi["properties"]["maxmmi"] ) }}</b-td>
                                          <b-td >{{ dyfi["properties"]["num-responses"] }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                                <b-carousel id="dyfis" ref="dyfis" :interval="0"  controls indicators fade>
                                    <b-carousel-slide
                                        :img-src="sortDYFI[allowedDYFI]['contents'][selectedFeature.id + '_ciim.jpg']['url']"
                                        :id="sortDYFI[allowedDYFI]['indexid']"
                                    >
                                    </b-carousel-slide>
                                    <b-carousel-slide
                                        :img-src="sortDYFI[allowedDYFI]['contents'][selectedFeature.id + '_ciim_geo.jpg']['url']"
                                        :id="sortDYFI[allowedDYFI]['indexid']"
                                    >
                                    </b-carousel-slide>
                                </b-carousel>
                            </template>                   
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                Links
                            </template>
                            <template v-if="(!toProductTypesArray(selectedFeature.properties.types).includes('scitech-link') &&
                                        !toProductTypesArray(selectedFeature.properties.types).includes('impact-link')) || selectedFeature.properties.products == undefined">
                                N/A
                            </template>
                            <template v-else>
                                <b-list-group>
                                    <template v-if="toProductTypesArray(selectedFeature.properties.types).includes('impact-link')">
                                        <b-list-group-item
                                            v-for="(impact_link, index) in selectedFeature['properties']['products']['impact-link']"
                                            :href="impact_link.properties.url"
                                            :key="index"
                                            target="_blank"
                                        >
                                            {{ impact_link.properties.text }}
                                             <div class="text-truncate small">Updated: {{ formatDate(impact_link.updateTime) }}</div>
                                        </b-list-group-item>
                                    </template>
                                    <template v-if="toProductTypesArray(selectedFeature.properties.types).includes('scitech-link')">
                                        <b-list-group-item
                                            v-for="(scitech_link, index) in selectedFeature['properties']['products']['scitech-link']"
                                            :href="scitech_link.properties.url"
                                            :key="index"
                                            target="_blank"
                                        >
                                            {{ scitech_link.properties.text }}
                                            <div class="text-truncate small">Updated: {{ formatDate(scitech_link.updateTime) }}</div>
                                        </b-list-group-item>
                                    </template>
                                </b-list-group>
                            </template>
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                Nearby Cities
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('nearby-cities')  || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else >
                                <b-table-simple id='nearbyCityTable' responsive class="table">
                                    <b-thead>
                                        <b-tr>
                                          <b-th scope="col">Name</b-th>
                                          <b-th scope="col">Distance</b-th>
                                          <b-th scope="col">Direction</b-th>
                                          <b-th scope="col">Location</b-th>
                                          <b-th scope="col">Population</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody >
                                        <b-tr style="white-space: nowrap" v-for="(city, index)  in nearbyCities" :key="index">
                                          <b-td >{{ city.name }}</b-td>
                                          <b-td >{{ formatDistance(city.distance, originallyInt=true) }}</b-td>
                                          <b-td >{{ city.direction }}</b-td>
                                          <b-td >{{ formatLat(parseFloat(city.latitude))}}, {{ formatLon(parseFloat(city.longitude))}}</b-td>
                                          <b-td >{{ city.population ? city.population : 'null'}}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                                <hr>
                                Updated: {{ formatDate(selectedFeature['properties']['products']['nearby-cities'][0].updateTime) }}
                            </template>     
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                Focal Mechanism
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('focal-mechanism') || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else>
                                 <b-table-simple id="focalMechTable" responsive class="table">
                                    <b-thead>
                                        <b-tr class="no-wrapping">
                                          <b-th scope="col" >Code</b-th>
                                          <b-th scope="col" >NP1 Strike</b-th>
                                          <b-th scope="col">NP1 Dip</b-th>
                                          <b-th scope="col">NP1 Rake</b-th>
                                          <b-th scope="col" >NP2 Strike</b-th>
                                          <b-th scope="col">NP2 Dip</b-th>
                                          <b-th scope="col">NP2 Rake</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody >
                                        <b-tr class="detail-table-rows" v-for="(focalMech, index) in sortFocalMechanism" :key="focalMech.indexid" :class="{ 'table-info': index === 0 }" v-on:click="changeSelectedBeachball(index,'beachBalls'); changeSelectedRow(index, 'focalMechTable');">
                                          <b-td >{{ focalMech.code }}</b-td>
                                          <b-td >{{ focalMech["properties"]["nodal-plane-1-strike"] }}</b-td>
                                          <b-td >{{ focalMech["properties"]["nodal-plane-1-dip"] }}</b-td>
                                          <b-td >{{ focalMech["properties"]["nodal-plane-1-rake"] }}</b-td>
                                          <b-td >{{ focalMech["properties"]["nodal-plane-2-strike"] }}</b-td>
                                          <b-td >{{ focalMech["properties"]["nodal-plane-2-dip"] }}</b-td>
                                          <b-td >{{ focalMech["properties"]["nodal-plane-2-rake"] }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>

                                <b-carousel id="beachBalls" ref="beachBalls" :interval="0"   fade>
                                <a :href="beachballURL" target="_blank">
                                    <b-carousel-slide
                                        :img-src="beachballURL"
                                        :id="sortFocalMechanism[allowedBeachBall]['indexid']"
                                    >
                                    </b-carousel-slide>
                                </a>
                                </b-carousel>
                                <hr>
                                Updated: {{ formatDate(selectedFeature['properties']['products']['focal-mechanism'][0].updateTime) }}
                            </template>
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                Ground Failure
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('ground-failure')  || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else >
                                    {{ selectedFeature['properties']['products']['ground-failure'] }}
                                    <hr>
                                    Updated: {{ formatDate(selectedFeature['properties']['products']['ground-failure'][0].updateTime) }}
                            </template>
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                Moment Tensor
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('moment-tensor') || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else>
                                <b-table-simple id='momentTensorTable' responsive class="table">
                                    <b-thead>
                                        <b-tr>
                                          <b-th scope="col">Catalog</b-th>
                                          <b-th scope="col">Mag</b-th>
                                          <b-th scope="col">Depth</b-th>
                                          <b-th scope="col" style="white-space:nowrap;">% DC</b-th>
                                          <b-th scope="col">Weight</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody >
                                        <b-tr class="detail-table-rows" v-for="(momentTensor, index)  in sortMomentTensors" :key="index" :class="{ 'table-info': index === 0 }" v-on:click="changeSelectedRow(index, 'momentTensorTable');">
                                          <b-td >{{ momentTensor.source.toUpperCase() }}</b-td>
                                          <b-td >{{ formatMag(parseFloat(momentTensor["properties"]["derived-magnitude"])) }}{{ momentTensor["properties"]["derived-magnitude-type"] }}</b-td>
                                          <b-td >{{ formatDepth(parseFloat(momentTensor["properties"]["derived-depth"])) }}</b-td>
                                          <b-td >{{ formatDoubleCouple(momentTensor["properties"]["percent-double-couple"])}}%</b-td>
                                          <b-td >{{ momentTensor["preferredWeight"] }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                                <hr>
                                Updated: {{ formatDate(selectedFeature['properties']['products']['moment-tensor'][0].updateTime) }}
                            </template>                      
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                OAF
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('oaf')  || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else >
                                {{ selectedFeature['properties']['products']['oaf'] }}
                                <hr>
                                Updated: {{ formatDate(selectedFeature['properties']['products']['oaf'][0].updateTime) }}
                            </template>
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                Origin
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('origin') || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else>
                                <b-table-simple id="originTable" responsive class="table">
                                    <b-thead>
                                        <b-tr>
                                          <b-th scope="col">Catalog</b-th>
                                          <b-th scope="col">Mag</b-th>
                                          <b-th scope="col">Time</b-th>
                                          <b-th scope="col">Depth</b-th>
                                          <b-th scope="col">Status</b-th>
                                          <b-th scope="col">Location</b-th>
                                          <b-th scope="col">Weight</b-th>

                                        </b-tr>
                                    </b-thead>
                                    <b-tbody >
                                        <b-tr class="detail-table-rows" v-for="(origin, index)  in sortOrigins" :key="index" :class="{ 'table-info': index === 0 }" v-on:click="changeSelectedRow(index, 'originTable');">
                                          <b-td >{{ origin.source.toUpperCase() }}</b-td>
                                          <b-td >{{ formatMag(parseFloat(origin.properties.magnitude)) }}{{ origin["properties"]["magnitude-type"] }}</b-td>
                                          <b-td >{{ formatTime(origin.properties.eventtime) }}</b-td>
                                          <b-td >{{ formatDepth(parseFloat(origin.properties.depth)) }}</b-td>
                                          <b-td >{{ formatStatus(origin["properties"]["review-status"]) }}</b-td>
                                          <b-td >{{ formatLat(parseFloat(origin.properties.latitude)) }},{{ formatLon(parseFloat(origin.properties.longitude)) }} </b-td>
                                          <b-td >{{ origin["preferredWeight"] }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>

                                <hr>
                                Updated: {{ formatDate(selectedFeature['properties']['products']['origin'][0].updateTime) }}
                            </template>
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                PAGER
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('losspager')  || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else>  
                                <b-table-simple id="pagerTable" responsive class="table">
                                      <b-thead>
                                        <b-tr>
                                          <b-th scope="col" >Catalog</b-th>
                                          <b-th scope="col">Alert Level</b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody >
                                        <b-tr class="detail-table-rows" v-for="(pager, index) in sortPAGER" :key="pager.indexid" :class="{ 'table-info': index === 0 }" v-on:click="changeAllowedPAGER(index,'pager'); changeSelectedRow(index, 'pagerTable');">
                                          <b-td >{{ pager.source.toUpperCase() }}</b-td>
                                          <b-td >{{ pager["properties"]["alertlevel"].toUpperCase()  }}</b-td>
                                        </b-tr>
                                      </b-tbody>
                                 </b-table-simple>

                                 <b-carousel id="pagers" ref="pagers" :interval="0" indicators fade>
                                     <b-carousel-slide
                                         :img-src="sortPAGER[allowedPAGER]['contents']['alertfatal.png']['url']"
                                         :id="sortPAGER[allowedPAGER]['indexid']"
                                     >
                                     </b-carousel-slide>
                                     <b-carousel-slide
                                         :img-src="sortPAGER[allowedPAGER]['contents']['alertecon.png']['url']"
                                         :id="sortPAGER[allowedPAGER]['indexid']"
                                     >
                                     </b-carousel-slide>
                                     <b-carousel-slide
                                          :img-src="sortPAGER[allowedPAGER]['contents']['exposure.png']['url']"
                                          :id="sortPAGER[allowedPAGER]['indexid']"
                                      >
                                      </b-carousel-slide>
                                 </b-carousel>

                                <b-link :href="selectedFeature['properties']['products']['losspager'][0]['contents']['onepager.pdf']['url']" target="_blank">One Page Summary</b-link>
                                <hr>
                                Updated: {{ formatDate(selectedFeature['properties']['products']['losspager'][0].updateTime) }}
                            </template>
                        </b-tab>

                        <b-tab v-bind:style="{height: detailHeight + 'px'}">
                            <template #title>
                                Phase Data
                            </template>
                            <template v-if="!toProductTypesArray(selectedFeature.properties.types).includes('phase-data')  || selectedFeature.properties.products == undefined" >
                                N/A
                            </template>
                            <template v-else >
                                {{ selectedFeature['properties']['products']['phase-data'] }}
                                <hr>
                                Updated: {{ formatDate(selectedFeature['properties']['products']['phase-data'][0].updateTime) }}
                            </template>
                        </b-tab>


                        <b-tab title="GeoJson" v-bind:style="{height: detailHeight + 'px'}">
                              {{ selectedFeature ? selectedFeature : "N/A" }}
                        </b-tab>

                </b-tabs>

                </b-overlay>

                </template>

            </b-col>

            <!-- gotosettings -->
            <b-col id="settings" v-show="showSettings" order="4" class="scroll-class" v-bind:style="{height: mapHeight + 'px'}" :class="[showMap ? 'fixed-side' : 'flexed']">

                <b-navbar type="light" variant="light" sticky fixed="top" class="px-2 py-0">
                    <b-navbar-brand>
                        <b-icon icon="gear" title="Settings" /> Settings
                    </b-navbar-brand>

                    <b-navbar-nav class="ml-auto">
                        <b-button @click="resetSettings()" id="resetToDefault" class="btn-xs">Reset to Defaults</b-button>
                        <b-nav-item @click="toggleSettings()">
                            <b-icon icon="x-square" title="Close Settings" />
                        </b-nav-item>
                    </b-navbar-nav>
                </b-navbar>

                <div class="px-2 py-2">

                    <div class="mb-0 settings-header">
                        <h5 class="settings-header-text">Display</h5>
                    </div>

                    <div class="">
                        <div class="switch-container">
                            <div>Pan to and select new events</div>
                            <b-form-checkbox v-model="panToNewEvents" name="panToNewEvents" switch/>
                        </div>

                        <div class="switch-container">
                            <div>Show time as local</div>
                            <b-form-checkbox v-model="showLocalTime" name="showLocalTime" switch/>
                        </div>

                        <div class="switch-container">
                            <div>Show distance as miles</div>
                            <b-form-checkbox v-model="showMiles" name="showMiles" switch/>
                        </div>

                        <div class="switch-container">
                            <div>Wrap markers around meridian</div>
                            <b-form-checkbox v-model="wrappedMarkers" name="wrappedMarkers" switch/>
                        </div>

                        <b-form-group
                            id="markerDecorationOptionsGroup"
                            label="Selected decoration"
                            label-for="markerDecorationOptions"
                            label-cols="6"
                            class="my-0"
                        >
                            <b-form-select
                                id="markerDecorationOptions"
                                v-model="markerDecoration"
                                :options="markerDecorationOptions"
                                size="sm"
                            >
                            </b-form-select>
                         </b-form-group>

                    </div>

                    <div class="mb-0 settings-header">
                        <h5 class="mb-0 settings-header-text">Catalog</h5>
                    </div>

                    <div class="mb-2 small">
                        Last generated: {{ formatDate(catLastGenerated) }}
                    </div>

                    <div class="">

                        <b-form-group
                            id="catAutoUpdateOptionsGroup"
                            label="Auto-update interval"
                            label-for="catAutoUpdateOptions"
                            label-cols="6"
                            class="my-0"
                        >
                        <b-form-select
                            id="catAutoUpdateOptions"
                            v-model="catAutoUpdateOption"
                            :options="catAutoUpdateOptions"
                            @change="scheduleCatalogUpdate"
                            size="sm"
                         >
                         </b-form-select>
                         </b-form-group>

                        <b-form-group
                            id="reviewStatusOptionsGroup"
                            label="Review status"
                            label-for="reviewStatusOptions"
                            label-cols="6"
                            class="my-0"
                        >
                        <b-form-select
                            id="reviewStatusOptions"
                            v-model="reviewStatusOption"
                            :options="reviewStatusOptions"
                            size="sm"
                         >
                         </b-form-select>
                         </b-form-group>

                    </div>

                    <div class="settings-slider-section">
                        <h6>Magnitude</h6>
                        <div class="px-0 pt-1 pb-2">
                            <vue-slider
                                v-model="magFilter"
                                :min="0"
                                :max="10"
                                :interval="0.1"
                                :height="10"
                                :lazy="true"
                                :contained="true"
                                :tooltip="'active'"
                                :enable-cross="false"
                                :marks="[0,1,2,3,4,5,6,7,8,9,10]"
                                :dot-attrs="{ 'aria-label': 'Magnitude' }"
                                >
                            </vue-slider>
                        </div>
                    </div>

                    <div class="settings-slider-section">
                        <h6>Time</h6>
                        <div class="px-0 pt-1 pb-2">
                            <vue-slider
                                v-model="timeFilter"
                                :min="-7"
                                :max="0"
                                :interval="0.25"
                                :height="10"
                                :lazy="true"
                                :contained="true"
                                :tooltip="'active'"
                                :enable-cross="false"
                                :marks="{'-7':'-7d', '-6':'-6d', '-5':'-5d', '-4':'-4d', '-3':'-3d', '-2':'-2d', '-1':'-1d', '0':'Now'}"
                                :dot-attrs="{ 'aria-label': 'Time' }"
                                >
                            </vue-slider>
                        </div>
                    </div>

                    <template v-if="isAuthenticated">

                    <div class="mb-0 settings-header">
                        <h5 class="mb-0 settings-header-text">Real-Time Notifications</h5>
                    </div>

                    <div class="mb-2 small">
                        Last updated: {{ formatDate(rtNotifLastUpdateTime) }}
                    </div>

                    <div class="">

                        Enable real-time notifications

                        <div class="switch-container">
                            <div>Screen flash</div>
                            <b-form-checkbox v-model="showRTNotifScreenFlash" name="showRTNotifScreenFlash" class="ml-2" switch/>
                        </div>

                        <div class="switch-container">
                            <div>Audio alarm</div>
                            <b-form-checkbox v-model="showRTNotifAudioAlarm" name="showRTNotifAudioAlarm" class="ml-2" switch/>
                        </div>

                        <div class="switch-container">
                            <div>New event popup</div>
                            <b-form-checkbox v-model="showRTNotifToast" name="showRTNotifToast" class="ml-2" switch/>
                        </div>

                        <b-form-group
                            id="rtNotifAutoHideDelayOptionsGroup"
                            label="Time on screen"
                            label-size="sm"
                            label-for="rtNotifAutoHideDelayOptions"
                            label-cols="6"
                            class="ml-4 my-0"
                        >
                        <b-form-select
                            id="rtNotifAutoHideDelayOptions"
                            v-model="rtNotifAutoHideDelayOption"
                            :options="rtNotifAutoHideDelayOptions"
                            size="sm"
                         >
                         </b-form-select>
                         </b-form-group>

                    </div>

                    <div class="settings-slider-section">
                        <h6>Magnitude</h6>
                        <div class="px-0 pt-1 pb-2">
                            <vue-slider
                                v-model="rtNotifMagFilter"
                                :min="0"
                                :max="10"
                                :interval="0.1"
                                :height="10"
                                :lazy="true"
                                :contained="true"
                                :tooltip="'active'"
                                :enable-cross="false"
                                :marks="[0,1,2,3,4,5,6,7,8,9,10]"
                                :dot-options="rtNotifDotOptions"
                                :dot-attrs="{ 'aria-label': 'Magnitude' }"
                                >
                            </vue-slider>
                        </div>
                    </div>

                    <div class="settings-slider-section">
                        <h6>Time</h6>
                        <div class="px-0 pt-1 pb-2">
                            <vue-slider
                                v-model="rtNotifTimeFilter"
                                :min="-4"
                                :max="0"
                                :height="10"
                                :lazy="true"
                                :contained="true"
                                :tooltip="'active'"
                                :enable-cross="false"
                                :marks="{'-4':'-4h', '-3':'-3h', '-2':'-2h', '-1':'-1h', '0':'Now'}"
                                :dot-options="rtNotifDotOptions"
                                :dot-attrs="{ 'aria-label': 'Time' }"
                                >
                            </vue-slider>
                        </div>
                    </div>

                    <div class="mb-2 settings-header">
                        <h5 class="mb-0 settings-header-text">Authentication</h5>
                    </div>

                    <div class="mb-2">
                         Logged in as <b>{{ displayName }}</b>
                        <b-button @click="signOut()" id="signOut" class="btn-xs float-right">Sign Out</b-button>
                    </div>

                    </template>

                    <!--
                    <div class="mb-0 settings-header">
                        <h5 class="mb-0 settings-header-text">Dev Settings</h5>
                    </div>

                    <div class="pt-2">

                        <b-form-group
                             id="markerStyleOptionsGroup"
                             label="Marker Style"
                             label-for="markerStyleOptions"
                             label-cols="6"
                             class="my-0"
                        >
                        <b-form-select
                            id="markerStyleOptions"
                            v-model="markerStyleOption"
                            :options="markerStyleOptions"
                            size="sm"
                        />
                        </b-form-group>

                        <b-form-group
                            id="dataSourceOptionsGroup"
                            label="Data source"
                            label-for="dataSourceOptions"
                            label-cols="6"
                            class="my-0"
                        >
                        <b-form-select
                            id="dataSourceOptions"
                            v-model="dataSourceOption"
                            :options="dataSourceOptions"
                            size="sm"
                        >
                        </b-form-select>
                        </b-form-group>

                    </div>
                    -->

                </div>

            </b-col>

            <!-- gotohelp -->
            <b-col v-show="showHelp" order="1" class="scroll-class flexed" v-bind:style="{height: mapHeight + 'px'}">

                <b-navbar type="light" variant="light" sticky fixed="top" class="px-2 py-0">
                    <b-navbar-brand>
                        <b-icon icon="question-circle" title="Help" /> Help
                    </b-navbar-brand>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item @click="toggleHelp()">
                            <b-icon icon="x-square" title="Close Help" />
                        </b-nav-item>
                    </b-navbar-nav>
                </b-navbar>

                <div class="px-2 py-2">

                    <p><b-link href="CISN_Display_Web_User_Guide.pdf" target="_blank">CISN Display Web User Guide</b-link></p>

                    <template v-if="isAuthenticated">
                        <p><b-link href="mailto:cisn-quakewatch-cloud@isti.com">Create support ticket</b-link></p>
                    </template>
                    <!-- gitlab-inbound+lantolik-quakewatch-web-263-issue-@isti.com -->

                    <template v-else>
                        <p><b-link href="mailto:public-cisn-web@isti.com">Create support ticket</b-link></p>
                    </template>

                    <div>
                        <p>
                            Quake Display is a web-based version of the CISN Display client, 
                            allowing both citizens and first responders alike to view details about earthquakes in regions of interest.
                            This website will be updated with new earthquakes as they are detected, and new details as they are calculated.
                        </p>
                    </div>

                    <div>
                        <h6 class="contrib-header" >Contributors:</h6>
                        <ul>
                            <li>California Geological Survey (CGS)</li>
                            <li>California Governor's Office of Emergency Services (CalOES)</li>
                            <li>United States Geological Survey (USGS)</li>
                            <li>Instrumental Software Technologies, Inc. (ISTI)</li>
                        </ul>
                    </div>


                    <p>Version 0.1.31, 2024-09-30</p>

                </div>

            </b-col>

        </b-row>
        </b-container>

    </div>

</template>

<script>

    import { loginRequest, tokenRequest } from '@/js/authConfig'

    import axios from 'axios'
    import axiosRetry from 'axios-retry'
    import * as signalr from '@microsoft/signalr'
    import * as aml from '@/js/azure-maps-leaflet.min.js'
    import 'leaflet-mouse-position'

    import rtNotifAudioFile from '@/assets/alarm.wav'

    import * as shp from 'shpjs'
    window.shp = shp
    require('@/js/elementOverlay.js')
    require('@/js/worldFile.js')

    console.log('import fdsnNetworksData ...')
    import fdsnNetworksData from '@/assets/json-files/fdsn_networks.json'

    console.log('import plateBoundariesData ...')
    import plateBoundariesData from '@/assets/json-files/PB2002_boundaries.json'

    console.log('import usFaultsData ...')
    import usFaultsData from '@/assets/json-files/ca_nv_faults.json'

    console.log('import usStatesData ...')
    import usStatesData from '@/assets/json-files/gz_2010_us_040_00_5m.json'

    console.log('import usCountiesData ...')
    import usCountiesData from '@/assets/json-files/gz_2010_us_050_00_5m_us_500k_ca.json'

    var volcanoAPI = 'https://volcanoes.usgs.gov/vsc/api/volcanoApi/geojson'

    var eventAPI    = process.env.VUE_APP_EVENT_API
    var signalrAuth = process.env.VUE_APP_SIGNALR_AUTH
    var mapAuth     = process.env.VUE_APP_MAP_AUTH
    var mapCid      = process.env.VUE_APP_MAP_CID


    const detailBarHeight = 94;
    const listBarHeight = 134;

    export default {
        name: 'Quake Display',

        data: function () {

            var initialData = {

                // authentication
                isAuthenticated: false,
                displayName: null,

                // height vars
                mapHeight: 1000, //getting initial height being enough to load enough squares.
                listHeight: 0,
                detailHeight: 0,

                // global vars
                selectedFeature: [],
                hoveredFeature: [],
                allFeatures: [],
                catFeatures: [],
                rtFeatures: [],
                rtDeleted: 0,
                rtUpdated: 0,
                rtInserted: 0,
                showMap: true,
                showList: true,
                showSettings: false,
                showEvent: false,
                showHelp: false,
                showStates: [],
                loading: true,
                errored: false,
                signalrConnection: null,
                invalidMags: 0,
                minEventTime: 0,
                maxEventTime: 0,
                window: { width: 0, height: 0 },
                shakemaps: [],
                showShakemapIntensity: false,
                showShakemapStations: false,
                showShakemapShapefile: true,
                shakemapColors:  ['#ffffff', '#bfccff', '#a0e6ff', '#80ffff', '#7aff92', '#ffff00', '#ffc800', '#ff9100', '#ff0000', '#c80000'],
                shakemapOpacity: ['0',       '0.5',     '0.5',     '0.5',     '0.5',     '0.5',     '0.5',     '0.5',     '0.5',     '0.5'],

                // details vars
                showDetailOverlay: false,
                tabIndex: 0,
                fdsnNetworks: fdsnNetworksData,
                selectedShakemap: 0,
                allowedBeachBall: 0,
                allowedDYFI: 0,
                allowedPAGER: 0,
                nearbyCities:{},

                // map vars
                zoom: null,
                minZoom: 1,
                center: null,
                bounds: null,
                tooltipOpacity: 0.9,
                layersControl: null,
                layerIndex: null,
                baseLayers: [],
                baseLayerLight: null,
                baseLayerDark: null,
                baseLayerImagery: null,
                showEarthquakes: null,
                volcanoes: [],
                showVolcanoes: null,
                plateBoundaries: plateBoundariesData,
                showPlateBoundaries: null,
                usFaults: usFaultsData,
                showUsFaults: null,
                usStates: usStatesData,
                showUsStates: null,
                usCounties: usCountiesData,
                showUsCounties: null,
                labelsLayer: null,
                showLabels: null,
                infraredLayer: null,
                showInfrared: null,
                weatherLayer: null,
                showWeather: null,
                trafficFlowLayer: null,
                showTrafficFlow: null,
                trafficIncidentLayer: null,
                showTrafficIncident: null,
                showSquareMarkers: null,
                showClassicColors: null,


                // settings vars
                autoUpdateInterval: 600000,
                now: new Date().getTime(),
                panToNewEvents: null,
                showLocalTime: null,
                showMiles: null,
                wrappedMarkers: null,
                markerDecoration: null,
                markerDecorationOptions: [
                    { value: 'bold', text: 'bold' },
                    { value: 'cross', text: 'cross' },
                    { value: 'highlight', text: 'highlight' },
                ],
                showRTNotifToast: null,
                showRTNotifScreenFlash: null,
                showRTNotifAudioAlarm: null,
                magFilter: null,
                timeFilter: null,
                t0: null,
                t1: null,
                m0: null,
                m1: null,
                rtNotifMagFilter: null,
                rtNotifTimeFilter: null,
                rtNotifDotOptions: [{disabled:false},{disabled:true}],

                markerStyleOption: 'markers',
                markerStyleOptions: [
                    { value: 'none', text: 'none' },
                    { value: 'markers', text: 'markers' },
                    { value: 'circlemarkers', text: 'circle markers' },
                ],

                // cookie vars
                allowCookies: false,
                cookieModal: true,

                // list vars
                listFields: [ { key: 'feature', thStyle: { display: 'none' }, } ],
                listFollowsMap: null,
                listSortField: "properties.time",
                listSortDescending: true,

                listSortOption: null,
                listSortOptions: [
                    { value: 'time-desc',   text: 'Newest First' },
                    { value: 'time-asc',    text: 'Oldest First' },
                    { value: 'mag-desc',    text: 'Largest Magnitude First' },
                    { value: 'mag-asc',     text: 'Smallest Magnitude First' },
                ],

                dataSourceOption: 'all',
                dataSourceOptions: [
                    { value: 'all',  text: 'all' },
                    { value: 'rt',   text: 'rt' },
                    { value: 'cat',  text: 'cat' },
                ],

                reviewStatusOption: 'all',
                reviewStatusOptions: [
                    { value: 'all',       text: 'All' },
                    { value: 'automatic', text: this.formatStatus('automatic') },
                    { value: 'reviewed',  text: this.formatStatus('reviewed') },
                ],

                catLastGenerated: null,
                catAutoUpdateHandler: null,
                catAutoUpdateOption: null,
                catAutoUpdateOptions: [
                    { value: 1,  text: '1 minute' },
                    { value: 5,  text: '5 minutes' },
                    { value: 30, text: '30 minutes' },
                ],

                rtNotifLastUpdateTime: null,
                rtNotifNoAutoHide: false,
                rtNotifAutoHideDelayOption: null,
                rtNotifAutoHideDelayOptions: [
                    { value: 5000,    text: '5 seconds' },
                    { value: 30000,   text: '30 seconds' },
                    { value: 60000,   text: '1 minute' },
                    { value: 300000,  text: '5 minutes' },
                    { value: 1800000, text: '30 minutes' },
                    { value: 0,       text: 'Until clicked' },
                ],

                rtNotifNumFlashes: 5,
                rtNotifFlashDuration: 500,

                // pagination stuff
                perPage: 50,
                currentPage: 1,
                justChangedPage: false,
                currentList: null,
                tableIndex: null,

                // map presets
                presets: [
                    { value: { lat: '62.5', lon: '-155.0', zoom: '4' }, text: 'Alaska' },
                    { value: { lat: '40.0', lon: '-123.0', zoom: '6' }, text: 'California (North)' },
                    { value: { lat: '33.0', lon: '-120.0', zoom: '6' }, text: 'California (South)' },
                    { value: { lat: '36.3', lon:  '-95.8', zoom: '6' }, text: 'Central U.S.' },
                    { value: { lat: '20.5', lon: '-157.3', zoom: '7' }, text: 'Hawaii' },
                    { value: { lat: '18.0', lon:  '-66.0', zoom: '7' }, text: 'Puerto Rico' },
                    { value: { lat: '38.0', lon:  '-95.5', zoom: '4' }, text: 'U.S.' },
                    { value: { lat:  '0.0', lon:    '0.0', zoom: '1' }, text: 'World' },
                ],
            }

            Object.assign(initialData, this.initialSettingsState())

            return initialData;
        },

        watch: {

            //column cookies

            showEvent: function() {
                if (this.allowCookies){
                    $cookies.set("showEvent", this.showEvent)
                }
            },

            showMap: function() {
                if (this.allowCookies){
                    $cookies.set("showMap", this.showMap)
                }
            },

            showList: function() {
                if (this.allowCookies){
                    $cookies.set("showList", this.showList)
                }
            },

            showSettings: function() {
                if (this.allowCookies){
                    $cookies.set("showSettings", this.showSettings)
                }
            },

            showHelp: function() {
                if (this.allowCookies){
                    $cookies.set("showHelp", this.showHelp)

                    if (this.showHelp){
                        $cookies.set("showStates", this.showStates.map(x => x ? 1 : 0 ))
                    } else {
                        $cookies.remove("showStates")
                    }
                }
            },

            //settings cookie

            panToNewEvents: function(){
                if (this.allowCookies){
                     $cookies.set("panToNewEvents", this.panToNewEvents)
                }
            },

            showLocalTime: function(){
                if (this.allowCookies){
                     $cookies.set("showLocalTime", this.showLocalTime)
                }
            },

            showMiles: function(){
                if (this.allowCookies){
                    $cookies.set("showMiles", this.showMiles)
                }
            },

            wrappedMarkers: function(){
                if (this.allowCookies){
                    $cookies.set("wrappedMarkers", this.wrappedMarkers)
                }
            },

            markerDecoration: function(){
                if (this.allowCookies){
                    $cookies.set("markerDecoration", this.markerDecoration)
                }
            },

            showRTNotifToast: function(){
                console.log("showRTNotifToast: " + this.showRTNotifToast)
                if (this.allowCookies){
                    $cookies.set("showRTNotifToast", this.showRTNotifToast)
                }
            },

            showRTNotifScreenFlash: function(){
                console.log("showRTNotifScreenFlash: " + this.showRTNotifScreenFlash)
                if (this.allowCookies){
                    $cookies.set("showRTNotifScreenFlash", this.showRTNotifScreenFlash)
                }
            },

            showRTNotifAudioAlarm: function(){
                console.log("showRTNotifAudioAlarm: " + this.showRTNotifAudioAlarm)
                if (this.allowCookies){
                    $cookies.set("showRTNotifAudioAlarm", this.showRTNotifAudioAlarm)
                }
            },

            catAutoUpdateOption: function(){
                if (this.allowCookies){
                    $cookies.set("catAutoUpdateOption", this.catAutoUpdateOption)
                }
            },

            reviewStatusOption: function(){
                if (this.allowCookies){
                    $cookies.set("reviewStatusOption", this.reviewStatusOption)
                }
            },

            dataSourceOption: function(){
                if (this.allowCookies){
                    $cookies.set("dataSourceOption", this.dataSourceOption)
                }
            },

            magFilter: function(){
                if (this.allowCookies){
                    $cookies.set("magFilter", this.magFilter)
                }
            },

            timeFilter: function(){
                if (this.allowCookies){
                    $cookies.set("timeFilter", this.timeFilter)
                }
            },

            rtNotifAutoHideDelayOption: function(){
                if (this.allowCookies){
                    $cookies.set("rtNotifAutoHideDelayOption", this.rtNotifAutoHideDelayOption)
                }
            },

            rtNotifMagFilter: function(){
                if (this.allowCookies){
                    $cookies.set("rtNotifMagFilter", this.rtNotifMagFilter)
                }
            },

            rtNotifTimeFilter: function(){
                if (this.allowCookies){
                    $cookies.set("rtNotifTimeFilter", this.rtNotifTimeFilter)
                 }
            },

            //map cookies

            layerIndex: function(){
                if (this.allowCookies){
                    $cookies.set("layerIndex", this.layerIndex)
                    this.changeBaseLayer(this.layerIndex, this.layerIndex)
                }
            },

            showEarthquakes: function(){
                if (this.allowCookies){
                    $cookies.set("showEarthquakes", this.showEarthquakes)
                }
            },

            showVolcanoes: function(){
                if (this.allowCookies){
                    $cookies.set("showVolcanoes", this.showVolcanoes)
                }
            },

            showUsFaults: function(){
                if (this.allowCookies){
                    $cookies.set("showUsFaults", this.showUsFaults)
                }
            },

            showPlateBoundaries: function(){
                if (this.allowCookies){
                    $cookies.set("showPlateBoundaries", this.showPlateBoundaries)
                }
            },

            showUsStates: function(){
                if (this.allowCookies){
                    $cookies.set("showUsStates", this.showUsStates);
                }
            },

            showUsCounties: function(){
                if (this.allowCookies){
                    $cookies.set("showUsCounties", this.showUsCounties)
                }
            },

            showLabels: function(){
                if (this.allowCookies){
                    $cookies.set("showLabels", this.showLabels)
                }
                this.toggleLabels(this.showLabels)
            },

            showTrafficFlow: function(){
                if (this.allowCookies){
                    $cookies.set("showTrafficFlow", this.showTrafficFlow)
                }
                this.toggleTrafficFlow(this.showTrafficFlow)
            },

            showTrafficIncident: function(){
                if (this.allowCookies){
                    $cookies.set("showTrafficIncident", this.showTrafficIncident)
                }
                this.toggleTrafficIncident(this.showTrafficIncident)
            },

            showInfrared: function(){
                if (this.allowCookies){
                    $cookies.set("showInfrared", this.showInfrared)
                }
                this.toggleInfrared(this.showInfrared)
            },

            showWeather: function(){
                if (this.allowCookies){
                    $cookies.set("showWeather", this.showWeather)
                }
                this.toggleWeather(this.showWeather)
            },

            showClassicColors: function(){
                if (this.allowCookies){
                    $cookies.set("showClassicColors", this.showClassicColors)
                }
            },

            showSquareMarkers: function(){
                if (this.allowCookies){
                    $cookies.set("showSquareMarkers", this.showSquareMarkers)
                }
            },

            //list cookies

            justChangedPage: function(){
                if (this.selectedFeature && this.justChangedPage){
                    setTimeout(() => document.getElementById("eventList__row_"+this.selectedFeature.id).scrollIntoView(), 0)
                    this.justChangedPage=false
                }
            },

            listFollowsMap: function(){
                if (this.allowCookies){
                    $cookies.set("listFollowsMap", this.listFollowsMap)
                }
            },

            listSortOption: function(){
                if (this.allowCookies){
                    $cookies.set("listSortOption", this.listSortOption)
                }
                this.listSortChange(this.listSortOption)
            },

            selectedFeature: function(){
                if (this.allowCookies){
                    $cookies.set("selectedFeatureID", this.selectedFeature.id)
                    console.log('setting cookie selectedFeatureID:' + this.selectedFeature.id)
                }
                if (this.toProductTypesArray(this.selectedFeature.properties.types).includes('nearby-cities')){
                    this.getNearbyCities()
                }
            },

        },

        computed: {

            console: () => console,

            signalrState: function () {
                if (this.signalrConnection != null) {
                    console.log('signalrState: ' + this.signalrConnection.state)
                    return this.signalrConnection.state
                } else {
                    return 'Disconnected'
                }
            },

            featuresCount: function () {
                return this.features.length
            },

            filter1Count: function () {
                return this.filter1.length
            },

            filter2Count: function () {
                return this.filter2.length
            },

            filter3Count: function () {
                return this.filter3.length
            },

            features: function() {

                // get the rt features (except the 'status=deleted' events)
                if (this.dataSourceOption === 'rt') {
                    return this.rtFeatures.filter(feature => feature.properties.status != 'deleted')

                // get the cat features (there are no deleted events present by definition)
                } else if (this.dataSourceOption === 'cat') {
                    return this.catFeatures

                // merge the two data sources together !!
                // this makes a new, merged array, and also cleans up the rt array
                } else if (this.dataSourceOption === 'all') {

                    console.log('----- getAllFeatures begin -----')
                    console.log('rtFeatures:' + this.rtFeatures.length)
                    console.log('catFeatures:' + this.catFeatures.length)

                    // lets start with the catalog ...
                    this.allFeatures = [...this.catFeatures]
                    console.log('allFeatures:' + this.allFeatures.length)

                    // lets keep track of how we affect the catalog with the rt events
                    var deleted   = 0
                    var updated   = 0
                    var inserted  = 0

                    // iterate through each of the rtFeatures, and merge into catFeatures as needed
                    for (var i = 0; i < this.rtFeatures.length; i++) {

                        var thisFeature = this.rtFeatures[i]
                        console.log('thisFeature: ' + thisFeature.id)

                        // this rt feature is the same age or older than the current catalog.
                        // delete it since it has expired.
                        // do not try to merge it
                        if (thisFeature.properties.updated <= this.catLastGenerated) {
                            this.rtFeatures.splice(i, 1)
                            console.log('deleted ' + this.formatEventID(thisFeature.id) + ' from rtFeatures - older than catalog')

                        // this rt feature is newer than the catalog.  merge it
                        } else {

                            // lookup the index of this feature in the catalog, -1 if it doesn't exist
                            var index = this.allFeatures.findIndex(function(element, i) {
                                return element.id === thisFeature.id
                            })

                            // this rt feature is marked to be deleted
                            if (thisFeature.properties.status === 'deleted') {

                                // if its still in the catalog, get rid of it
                                if (index >= 0) {
                                    this.allFeatures.splice(index, 1)
                                    deleted++
                                    console.log('deleted feature from allFeatures ...')
                                }

                            // this rt feature contains new information, lets use it
                            } else {

                                // this feature doesn't exist, let's insert it
                                if (index < 0) {

                                    // push this to the catalog
                                    this.allFeatures.push(thisFeature)
                                    inserted++
                                    console.log('added feature to allFeatures ...')

                                // this feature exists already, let's update it
                                } else {

                                    // update the catalog with the feature
                                    this.allFeatures.splice(index, 1, thisFeature)
                                    updated++
                                    console.log('updated feature to allFeatures ...')
                                }

                                // let's see if there are any associated events that need to removed locally
                                let ids = thisFeature.properties.ids.split(",").filter(el => { return el != null && el != '' && el != thisFeature.id })

                                // iterate through each of the associated events
                                for (var j = 0; j < ids.length; j++) {

                                    var thisAssocFeatureId = ids[j]

                                    // lookup the index of this feature, -1 if it doesn't exist
                                    console.log('looking up associated event:' + thisAssocFeatureId)
                                    var jindex = this.allFeatures.findIndex(function(element, j) {
                                        return element.id === thisAssocFeatureId
                                    })

                                    // if the associated feature exists, then we will delete it
                                    if (jindex >= 0) {
                                        this.allFeatures.splice(jindex, 1)
                                        console.log('deleted associated feature ' + thisAssocFeatureId)

                                        // if the associated event was the selected event, make the incoming event the selected feature
                                        if (thisAssocFeatureId === this.selectedFeature.id) {
                                            this.selectedFeature = thisFeature
                                            console.log('updated selectedFeature')
                                        }
                                    }
                                }
                            }
                        }
                    }

                    console.log('rtFeatures:' + this.rtFeatures.length)
                    console.log('catFeatures:' + this.catFeatures.length)
                    console.log('allFeatures:' + this.allFeatures.length)
                    console.log('allFeatures: deleted=' + deleted + ', updated=' + updated + ', inserted=' + inserted)
                    console.log('------ getAllFeatures end ------')

                    return this.allFeatures
                }
            },

            filter1: function () {
                return this.applyMagTimeFilterToEvents(this.features)
            },

            filter2: function () {
                return this.applyBoundsFilterToEvents(this.features)
            },

            filter3: function () {
                //console.log('filter3 ...')
                return this.applyBothFiltersToEvents(this.features)
            },

            isValidBounds: function(){
                return (this.bounds?._southWest.lng != this.bounds?._northEast.lng) && (this.bounds?._southWest.lat != this.bounds?._northEast.lat) 
            },

            sortMomentTensors: function() {
                return this.selectedFeature['properties']['products']['moment-tensor'].sort(function(x,y) {return y.preferredWeight-x.preferredWeight;})
            },

            sortOrigins: function() {
               return this.selectedFeature['properties']['products']['origin'].sort(function(x,y) {return y.preferredWeight-x.preferredWeight;})
            },

            sortShakemaps: function() {
                return this.selectedFeature['properties']['products']['shakemap'].sort(function(x,y) {return y.preferredWeight-x.preferredWeight;})
            },

            sortDYFI: function() {
                return this.selectedFeature['properties']['products']['dyfi'].sort(function(x,y) {return y.preferredWeight-x.preferredWeight;})
            },

            sortFocalMechanism: function() {
                return this.selectedFeature['properties']['products']['focal-mechanism'].sort(function(x,y){return y.preferredWeight-x.preferredWeight;})
            },
            
            beachballURL: function(){
                let allowedBeachballObject=this.sortFocalMechanism[this.allowedBeachBall]
                let source = allowedBeachballObject.source
                let mostOfFileName = allowedBeachballObject.code.replace('_fm',`.${source}fm`)
                let regexProp = new RegExp(mostOfFileName)
                var beachballContents=allowedBeachballObject['contents']
                var fileName = Object.keys(beachballContents).filter(key => regexProp.test(key) && !/html$/.test(key))[0];
                var propertyObtained = beachballContents[fileName]['url']
                return propertyObtained
            },

            sortPAGER: function() {
                return this.selectedFeature['properties']['products']['losspager'].sort(function(x,y) {return y.preferredWeight-x.preferredWeight;})
            },

            selectedFeatureNetworkName: function() {
                //console.log('selectedFeatureNetworkName')

                if (this.selectedFeature) {
                    // only search on current codes (end_date is null or 2599-12-31)
                    var network = this.fdsnNetworks.networks
                        .find(network => network.fdsn_code.toUpperCase() === this.selectedFeature.properties.net.toUpperCase() &&
                                        (network.end_date === null || network.end_date === '2599-12-31')
                    )
                    if (network !== undefined) return network.name
                }

                return ''
            },

            selectedFeatureUSGSUrl: function() {
                //console.log('selectedFeatureUSGSUrl')

                let eventPage = 'https://earthquake.usgs.gov/earthquakes/eventpage/<EVID>/executive'

                if (this.selectedFeature) {
                    return eventPage.replace('<EVID>', this.selectedFeature.id)
                } else {
                    return 'https://earthquake.usgs.gov/'
                }
            },

            selectedFeatureCESMDUrl: function() {
                //console.log('selectedFeatureCESMDUrl')

                let eventPage = 'https://www.strongmotioncenter.org/cgi-bin/CESMD/iqr_dist_DM2.pl?ID=<EVID>'

                if (this.selectedFeature) {
                    return eventPage.replace('<EVID>', this.selectedFeature.id)
                } else {
                    return 'https://www.strongmotioncenter.org/index.html'
                }
            },

            baseLayer: function () {
                //console.log('baseLayer')
                return this.baseLayers[this.layerIndex]
            },

            coordsToLatLng() {
                return function (coords) {
                    return new L.LatLng(coords[1], coords[0], coords[2])
                };
            },

            coordsToLatLngLeft() {
                return function (coords) {
                    return new L.LatLng(coords[1], coords[0] - 360, coords[2])
                };
            },

            coordsToLatLngRight() {
                return function (coords) {
                    return new L.LatLng(coords[1], coords[0] + 360, coords[2])
                };
            },

            plateBoundariesStyle() {
                return () => {
                    var boundariesColor="#FF0000";
                    return {
                        weight: 1.2,
                        fillOpacity: 0,
                        color: boundariesColor,
                        'background-color': boundariesColor
                    };
                };
            },

            plateBoundariesOptions() {
                return {
                    onEachFeature: this.plateBoundariesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLng
                };
            },

            plateBoundariesOptionsLeft() {
                return {
                    onEachFeature: this.plateBoundariesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngLeft
                };
            },

            plateBoundariesOptionsRight() {
                return {
                    onEachFeature: this.plateBoundariesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngRight
                };
            },

            plateBoundariesOnEachFeature() {
                return (feature, layer) => {
                    layer.bindTooltip(
                        "Plate boundary",
                        { permanent: false, sticky: true, className: 'tooltip', opacity: this.tooltipOpacity, }
                    );
                };
            },

            usFaultsStyle() {
                return () => {
                    var faultColor="#800000";
                    return {
                        weight: 1.2,
                        fillOpacity: 0,
                        color: faultColor,
                        "background-color": faultColor,
                    };
                };
            },

            usFaultsOptions() {
                return {
                    onEachFeature: this.usFaultsOnEachFeature,
                    coordsToLatLng: this.coordsToLatLng
                };
            },

            usFaultsOptionsLeft() {
                return {
                    onEachFeature: this.usFaultsOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngLeft
                };
            },

            usFaultsOptionsRight() {
                return {
                    onEachFeature: this.usFaultsOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngRight
                };
            },

            usFaultsOnEachFeature() {
                return (feature, layer) => {
                    layer.bindTooltip(
                        feature.properties.FAULT,
                        { permanent: false, sticky: true, className: 'tooltip', opacity: this.tooltipOpacity, }
                    );
                };
            },

            usStatesStyle() {
                return () => {
                    var statesColor = "#000000";
                    return {
                        weight: 0.5,
                        fillOpacity: 0,
                        color: statesColor,
                        'background-color': statesColor,
                    };
                };
            },

            usStatesOptions() {
                return {
                    onEachFeature: this.usStatesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLng
                };
            },

            usStatesOptionsRight() {
                return {
                    onEachFeature: this.usStatesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngRight
                };
            },

            usStatesOptionsLeft() {
                return {
                    onEachFeature: this.usStatesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngLeft
                };
            },

            usStatesOnEachFeature() {
                return (feature, layer) => {
                    layer.bindTooltip(
                        feature.properties.NAME,
                        { permanent: false, sticky: true, className: 'tooltip', opacity: this.tooltipOpacity, }
                    );
                };
            },

            usCountiesStyle() {
                return () => {
                    var countiesColor="#A9A9A9";
                    return {
                        weight: 0.4,
                        fillOpacity: 0,
                        color: countiesColor,
                        'background-color': countiesColor
                    };
                };
            },

            usCountiesOptions() {
                return {
                    onEachFeature: this.usCountiesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLng
                };
            },

            usCountiesOptionsLeft() {
                return {
                    onEachFeature: this.usCountiesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngLeft
                };
            },

            usCountiesOptionsRight() {
                return {
                    onEachFeature: this.usCountiesOnEachFeature,
                    coordsToLatLng: this.coordsToLatLngRight
                };
            },

            usCountiesOnEachFeature() {
                return (feature, layer) => {
                    layer.bindTooltip(
                        feature.properties.NAME + ' County',
                        { permanent: false, sticky: true, className: 'tooltip', opacity: this.tooltipOpacity, }
                    );
                };
            },

            shakemapShapefileStyle() {
                //console.log('shakemapShapefileStyle')

                return (feature) => {

                    var index = parseInt(parseFloat(feature.properties.PARAMVALUE) - 1 + 0.5, 10)
                    if (index < 0) {
                        index = 0
                    } else if (index >= this.shakemapColors.length) {
                        index = this.shakemapColors.length - 1
                    }

                    var styles = {
                        weight: 1,
                        color: this.shakemapColors[index],
                        opacity: this.shakemapOpacity[index],
                        fillColor: this.shakemapColors[index],
                        fillOpacity: this.shakemapOpacity[index],
                    }

                    return styles
                };
            },

            shakemapShapefileOnEachFeature() {
                //console.log('shakemapShapefileOnEachFeature')

                return (feature, layer) => {
                    var out = []
                    if (feature.properties?.PARAMVALUE && feature.properties?.PARAMVALUE.toString().toLowerCase() != 'null'){
                        layer.bindTooltip(
                            "intensity: " + feature.properties["PARAMVALUE"],
                            { permanent: false, sticky: true, className: 'tooltip', opacity: this.tooltipOpacity, }
                        );
                    }
                };
            },

            shakemapShapefileFilter() {
                //console.log('shakemapShapefileFilter')

                return (feature, layer) => {
                    return true
                };
            },

            shakemapStationsOnEachFeature() {
                //console.log('shakemapStationsOnEachFeature')

                return (feature, layer) => {

                    var out = []
                    if (feature.properties){
                        out.push("code: " + feature.properties.code)
                        out.push("name: " + feature.properties.name)
                        out.push("network: " + feature.properties.network)
                        out.push("distance: " + this.formatDistance(feature.properties.distance))
                        if (feature.properties?.intensity && feature.properties?.intensity.toString().toLowerCase() != "null"){
                            out.push("intensity: " + feature.properties.intensity)
                        }

                        layer.bindTooltip(
                            out.join("<br />"),
                            { permanent: false, sticky: true, className: 'tooltip', opacity: this.tooltipOpacity, }
                        );
                    }

                    var index = parseInt(parseFloat(feature.properties.intensity) - 1 + 0.5, 10)
                    if (index < 0) {
                        index = 0
                    } else if (index >= this.shakemapColors.length) {
                        index = this.shakemapColors.length - 1
                    }

                    var fill
                    if (feature.properties.intensity >= 0) {
                        fill = this.shakemapColors[index]
                    } else {
                        fill = "none"
                    }

                    var html
                    if (feature.properties.network.toLowerCase() === 'dyfi') {
                        html = '<svg width="12" height="12"><polygon points="5.87,1.276 10.8,4.716 9.0,10.8 2.94,10.8 1.276,4.716" stroke="black" stroke-opacity="0.5" fill="' + fill + '" fill-opacity="0.5"/></svg>'
                    } else {
                        html = '<svg width="12" height="12"><rect width="8" height="8" x="2" y="2" stroke="black" stroke-opacity="0.5" fill="' + fill + '" fill-opacity="0.5" transform="rotate(45, 6, 6)" />'
                    }

                    layer.setIcon(new L.divIcon({
                        className:"",
                        iconSize:[12,12],
                        iconAnchor:[6,12],
                        html:html
                    }))
                };
            },

            shakemapStationsFilter() {
                //console.log('shakemapStationsFilter')

                return (feature, layer) => {
                    //return feature.properties.intensity != 'null'
                    return true
                };
            },

            containsAnyOptionalOverlays: function () {
                return (this.showUsStates || this.showUsCounties || this.showUsFaults || this.showPlateBoundaries)
            },

        },

        methods: {
  
            changeBaseLayer: function (layerIndex, event) {
                //console.log('changeBaseLayer')
                //console.log(layerIndex)
                //console.log(event)

                this.baseLayers[this.layerIndex].layer.removeFrom(this.$refs.map.mapObject);
                this.layerIndex = event
                this.baseLayers[this.layerIndex].layer.addTo(this.$refs.map.mapObject);
            },

            mousewheelScrollsDropdown: function(){
                var controlButtons = document.getElementsByClassName("leaflet-control-container")[0]

                L.DomEvent.addListener(controlButtons, 'wheel', function (e) {

                	L.DomEvent.stopPropagation(e);
                });

            },

            handleResize: function() {
                let bannerHeights = this.$refs.appBar.$el.clientHeight + this.$refs.utilityHeader.clientHeight
                this.mapHeight     = window.innerHeight - (bannerHeights)
                this.listHeight    = window.innerHeight - (bannerHeights + listBarHeight)
                this.detailHeight  = window.innerHeight - (bannerHeights + detailBarHeight)

                this.window.width  = window.innerWidth
                this.window.height = window.innerHeight

                if (this.window.width < 800) {
                    if (this.showList) {
                        this.showMap      = false
                        this.showSettings = false
                        this.showEvent    = false
                    } else if (this.showEvent) {
                        this.showMap      = false
                        this.showSettings = false
                    } else if (this.showSettings) {
                        this.showMap      = false
                    }
                }
            },

            // toggle column functions

            toggleMap: function() {

                this.showMap = !this.showMap

                if (this.showMap) {
                    this.showHelp     = false
                    if (this.window.width < 800) {
                        this.showEvent    = false
                        this.showList     = false
                        this.showSettings = false
                    }

                // if this choice turns everything off, turn on the map
                } else if (!(this.showList || this.showEvent || this.showSettings)) {
                    this.showMap = true
                }


                setTimeout(() => this.$refs.map.mapObject.invalidateSize(), 0)
            },

            toggleList: function() {

                this.showList = !this.showList

                if (this.showList) {
                    this.showHelp     = false
                    if (this.window.width < 800) {
                        this.showMap      = false
                        this.showEvent    = false
                        this.showSettings = false
                    }

                // if this choice turns everything off, turn on the map
                } else if (!(this.showList || this.showEvent || this.showSettings)) {
                    this.showMap = true
                }


                setTimeout(() => this.$refs.map.mapObject.invalidateSize(), 0)

                if (this.selectedFeature.id){
                    setTimeout(() => this.makeSelectedFeature(this.selectedFeature), 0)
                }
            },

            toggleEvent: function() {

                this.showEvent = !this.showEvent

                if (this.showEvent) {
                    this.showHelp     = false
                    if (this.window.width < 800) {
                        this.showMap      = false
                        this.showList     = false
                        this.showSettings = false
                    }

                // if this choice turns everything off, turn on the map
                } else if (!(this.showList || this.showEvent || this.showSettings)) {
                    this.showMap = true
                }


                setTimeout(() => this.$refs.map.mapObject.invalidateSize(), 0)
            },

            toggleSettings: function() {

                this.showSettings = !this.showSettings

                if (this.showSettings) {
                    this.showHelp     = false
                    if (this.window.width < 800) {
                        this.showMap      = false
                        this.showList     = false
                        this.showEvent    = false
                    }

                // if this choice turns everything off, turn on the map
                } else if (!(this.showList || this.showEvent || this.showSettings || this.showHelp)) {
                    this.showMap = true
                }


                setTimeout(() => this.$refs.map.mapObject.invalidateSize(), 0)
            },

            toggleHelp: function() {
                this.showHelp = !this.showHelp

                // hide everything, but save the states
                if (this.showHelp) {
                    this.showStates   = [this.showMap, this.showList, this.showSettings, this.showEvent]
                    this.showMap      = false
                    this.showList     = false
                    this.showSettings = false
                    this.showEvent    = false

                // restore the states
                } else {
                    this.showMap      = this.showStates[0]
                    this.showList     = this.showStates[1]
                    this.showSettings = this.showStates[2]
                    this.showEvent    = this.showStates[3]
                }


                setTimeout(() => this.$refs.map.mapObject.invalidateSize(), 0)
            },

            // format functions

            formatEventID: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return value
                } else {
                    return ''
                }
            },

            formatDate: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    if (this.showLocalTime) {
                        return new Date(value).toLocaleString('en-US', { hourCycle: 'h23', timeZoneName: 'short' })
                    } else {
                        return new Date(value).toLocaleString('en-US', { hourCycle: 'h23', timeZoneName: 'short', timeZone: 'UTC' })
                    }
                } else {
                    return ''
                }
            },

            formatCachedDate: function(obj, name) {
              // obj has the attribute 'name'
              // if it does not have 'nameCache' then we add that attribute (as a cache)
              // this avoids repeated calls to formatDate which was causing the display to "stall"
              let cachedName = name + 'Cache'
              if (! obj.hasOwnProperty(cachedName)) {
                obj[cachedName] = this.formatDate(obj[name])
              }
              return obj[cachedName]
            },

            formatTime: function(value){
                if ((value !== undefined) && (value !== null)) {
                    if (this.showLocalTime) {
                        return new Date(value).toLocaleTimeString('en-US', { hourCycle: 'h23', timeZoneName: 'short' })
                    } else {
                        return new Date(value).toLocaleTimeString('en-US', { hourCycle: 'h23', timeZoneName: 'short', timeZone: 'UTC' })
                    }
                } else {
                    return ''
                }
            },

            formatPlace: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return value
                } else {
                    return ''
                }
            },

            formatMag: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return value.toFixed(1)
                } else {
                    return ''
                }
            },

            formatDepth: function(value, originallyInt=false) {
                let decimalPlaces = originallyInt ? 0 : 1
                if ((value !== undefined) && (value !== null)) {
                    if (this.showMiles) {
                        return (value * 0.621371).toFixed(decimalPlaces) + ' miles'
                    } else {
                        return value.toFixed(decimalPlaces) + ' km'
                    }
                } else {
                    return ''
                }
            },

            formatDistance: function(value, originallyInt=false) {
                if (originallyInt){
                    return this.formatDepth(value, true)
                }
                return this.formatDepth(value)
            },

            formatLat: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return value.toFixed(2)
                } else {
                    return ''
                }
            },

            formatLon: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return value.toFixed(2)
                } else {
                    return ''
                }
            },

            formatLatLng: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return value
                } else {
                    return ''
                }
            },

            formatStatus: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    if (value.toLowerCase() === 'reviewed') {
                        return 'Reviewed'
                    } else if (value.toLowerCase() === 'automatic') {
                        return 'Automatic'
                    }
                } else {
                    return 'Unknown'
                }
            },

            formatType: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return value.charAt(0).toUpperCase() + value.slice(1)
                } else {
                    return ''
                }
            },

            formatDoubleCouple: function(value) {
                if ((value !== undefined) && (value !== null)) {
                    return (parseFloat(value)*100).toFixed(0)
                } else {
                    return ''
                }
            },

            formatMMI: function(value){
                if (value == "None"){
                    return 'N/A'
                }
                if ((value !== undefined) && (value !== null)) {
                    var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},
                         roman = '',
                         i,
                         num = parseFloat(value).toFixed(0);
                     for ( i in lookup ) {
                       while ( num >= lookup[i] ) {
                         roman += i;
                         num -= lookup[i];
                       }
                     }
                     return roman;
                } else {
                    return 'N/A'
               }
            },

            // data processing functions

            getMinEventTime: function () {
                //console.log('getMinEventTime ...')

                if (this.features.length > 0) {
                    let min = this.features[0].properties.time
                    for (let i = 1, len=this.features.length; i < len; i++) {
                        let v = this.features[i].properties.time
                        min = (v < min) ? v : min
                    }
                    return min
                } else {
                    return 0
                }
            },

            getMaxEventTime: function () {
                //console.log('getMaxEventTime ...')

                if (this.features.length > 0) {
                    let max = this.features[0].properties.time
                    for (let i = 1, len=this.features.length; i < len; i++) {
                        let v = this.features[i].properties.time
                        max = (v > max) ? v : max
                    }
                    return max
                } else {
                    return 0
                }
            },

            filterMags: function () {
                //console.log('filterMags ...')
                return this.catFeatures
                    .filter(feature => {
                        if (Number.isFinite(feature.properties.mag)) {
                            // round to the tenths place
                            feature.properties.mag = Math.round(feature.properties.mag * 10) / 10
                            return true
                        } else {
                            console.log('invalid mag ... ')
                            console.log(feature)
                            this.invalidMags++
                            return false
                        }
                    })
                    .sort(function (x, y) { return x.properties.time - y.properties.time; })
            },
 
            toProductTypesArray: function(value) {
                return value.replace(/(^,)|(,$)/g, "").split(',')
            },

            // data retrieval functions

            getDetailedFeature: function(feature) {
                console.log('getDetailedFeature')
                console.log(feature.properties.detail)

                this.showDetailOverlay = true

                axios
                    .get(feature.properties.detail)
                    .then(response => {

                        // update the selectedFeature with the response
                        this.selectedFeature = response.data
                        var selectedFeatureID = this.selectedFeature.id
                        console.log('selectedFeature updated with geojson detail (' + selectedFeatureID + ')')

                        // lookup the selectedFeature in the catalog
                        var index = this.catFeatures.findIndex(function(element, i) {
                            return element.id == selectedFeatureID
                        })

                        // splice the geojson detail into the catalog
                        if (index >= 0) {
                            this.catFeatures.splice(index, 1, this.selectedFeature)
                            console.log('catFeatures updated with geojson detail (' + selectedFeatureID + ')')
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                    })
                    .finally(() => {
                        this.loading = false
                        this.showDetailOverlay = false
                    })
            },

            getCatFeatures: function() {

                console.log('------ getCatFeatures begin ------')
                console.log('catFeatures:' + this.catFeatures.length)

                axios
                    .get(eventAPI)
                    .then(response => {

                        console.log('incoming features:' + response.data.features.length)

                        // let's see which feature has the latest modification time
                        let incomingUpdated = response.data.metadata.generated
                        let existingUpdated = this.catLastGenerated

                        console.log('catalog incomingUpdated: ' + this.formatDate(incomingUpdated))
                        console.log('catalog existingUpdated: ' + this.formatDate(existingUpdated))

                        if (incomingUpdated == existingUpdated) {

                            console.log('incoming same as existing, keeping existing')

                        } else if (incomingUpdated < existingUpdated) {

                            console.log('incoming older than existing, keeping existing')

                        // only update the local catalog if the downloaded catalog is newer
                        } else if (incomingUpdated > existingUpdated) {

                            console.log('incoming newer than existing, using incoming')
                            const firstLoad = !this.catLastGenerated ? true : false
                            let existingNewestId;
                            if (this.panToNewEvents && !firstLoad){
                                const existingList = this.filter3
                                existingNewestId = existingList[existingList.length-1].id
                                console.log("lastNewest:"+ existingNewestId)
                            }

                            // assign the data
                            this.catFeatures      = response.data.features
                            this.catLastGenerated = response.data.metadata.generated

                            // filter the data
                            this.catFeatures = this.filterMags()
                            const incomingList = this.applyBothFiltersToEvents(this.catFeatures)
                            const incomingNewest = incomingList[incomingList.length-1]
                            if (this.panToNewEvents && !firstLoad & (existingNewestId != incomingNewest.id)){
                                this.openSelectedFeature(incomingNewest, true)
                                console.log("newNewest:"+ incomingNewest.id)
                            }
                            this.minEventTime = this.getMinEventTime()
                            this.maxEventTime = this.getMaxEventTime()

                            // initialize the selectedFeature cookie
                            this.initSelectedFeatureCookie();
                        }

                        // end logging
                        console.log('catFeatures:' + this.catFeatures.length)
                        console.log('------ getCatFeatures end ------')
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                    })
                    .finally(() => this.loading = false)

            },

            getRtFeatures: function(message) {

                console.log('----- getRtFeatures begin -----')
                console.log('rtFeatures:' + this.rtFeatures.length)
                console.log('incoming features:' + message.data.length)

                // lets keep track of how we affect the rt catalog with the rt events
                var deleted   = 0
                var updated   = 0
                var inserted  = 0

                for (var i = 0; i < message.data.length; i++) {

                    var thisFeature = message.data[i]
                    thisFeature.dataSource = 'rt'

                    var ageInMinutes = ( Date.now() - thisFeature.properties.time ) / ( 1000 * 60 )

                    console.log('feature: ' +
                        this.formatEventID(thisFeature.id) + ' | ' +
                        this.formatMag(thisFeature.properties.mag) + thisFeature.properties.magType + ' | ' +
                        this.formatStatus(thisFeature.properties.status) + ' | ' +
                        this.formatDate(thisFeature.properties.time) + ' (' + ageInMinutes.toFixed(1) + ' minutes old)')

                    // skip events that aren't defined
                    if (thisFeature.id === undefined || thisFeature.properties.time === undefined || thisFeature.properties.status === undefined) {

                        console.log('skipping ' + this.formatEventID(thisFeature.id) + ' (undefined)')

                    // skip events greater than 4 hours old (they're already in the FDSN, and/or updates aren't worthy of real-time notifications)
                    } else if (ageInMinutes > (60 * 24 * 7)) {

                        console.log('skipping ' + this.formatEventID(thisFeature.id) + ' (old)')

                    } else {

                        // lookup the index of this feature, -1 if it doesn't exist
                        var index = this.rtFeatures.findIndex(function(element, i) {
                            return element.id === thisFeature.id
                        })

                        // this feature doesn't exist, let's insert it
                        if (index < 0) {

                            // push this to the rt array
                            this.rtFeatures.push(thisFeature)
                            inserted++
                            console.log('inserted rtFeatures ...')

                            // lookup the index of this feature, -1 if it doesn't exist
                            var index = this.catFeatures.findIndex(function(element, i) {
                                return element.id === thisFeature.id
                            })

                            // this feature doesn't exist in the catalog, try to send out a notification
                            if (index < 0) {
                                console.log('new feature, will attempt to notify')
                                this.notifyFeature(thisFeature)
                            }

                        // this feature exists already, let's update it (if its newer).
                        // keep track of deleted events as well, since this gets merged with the catalog
                        } else {

                            // let's see which feature has the latest modification time
                            let incomingUpdated = thisFeature.properties.updated
                            let existingUpdated = this.rtFeatures[index].properties.updated

                            console.log('rtFeature incomingUpdated: ' + this.formatDate(incomingUpdated))
                            console.log('rtFeature existingUpdated: ' + this.formatDate(existingUpdated))

                            if (incomingUpdated > existingUpdated) {
                                this.rtFeatures.splice(index, 1, thisFeature)
                                updated++
                                console.log('incoming data newer, updated rtFeatures ...')
                            }
                        }
                    }
                }

                // should this be the updated time of the feature?
                this.rtNotifLastUpdateTime = Date.now()

                console.log('rtFeatures: deleted=' + deleted + ', updated=' + updated + ', inserted=' + inserted)
                console.log('rtFeatures:' + this.rtFeatures.length)
                console.log('------ getRtFeatures end ------')
            },

            async getNearbyCities(){
                try {
                    let url = this.selectedFeature['properties']['products']['nearby-cities'][0]['contents']['nearby-cities.json']['url']
                    const res = await fetch(url)
                    console.log(res)
                    this.nearbyCities = await res.json()
                } catch (error) {
                    
                }
            },

            applyBoundsFilterToEvents: function(eventFeatures) {
                let a0 = new Date().getTime()
                let returnVal = null

                // this is the map bounds filter, and will only get run if we are keeping the list in sync with the map (listFollowsMap)
                if (this.bounds && this.isValidBounds) {
                    returnVal = eventFeatures
                        .filter(feature => feature.geometry.coordinates[0] >= this.bounds._southWest.lng &&
                                           feature.geometry.coordinates[0] <= this.bounds._northEast.lng &&
                                           feature.geometry.coordinates[1] >= this.bounds._southWest.lat &&
                                           feature.geometry.coordinates[1] <= this.bounds._northEast.lat
                        )
                        .sort(function (x, y) { return x.properties.time - y.properties.time; })
                } else {
                    returnVal = eventFeatures
                }

                let a1 = new Date().getTime()
                console.log('applyBoundsFilterToEvents: ' + (a1 - a0) + 'ms')

                return returnVal
            },

            applyMagTimeFilterToEvents: function(eventFeatures) {
                let a0 = new Date().getTime()
                let returnVal = null

                // since we're running the filter, let's update 'now', otherwise 'now' gets updated on a timer
                //this.now = new Date().getTime()

                // start and end times are relative to 'now', and are bounded by the time slider
                this.t0 = this.now + (this.timeFilter[0] * 86400000)
                this.t1 = this.now + (this.timeFilter[1] * 86400000)

                this.m0 = this.magFilter[0]
                this.m1 = this.magFilter[1]

                // this is where the magic happens ...
                returnVal = eventFeatures
                    .filter(feature => {
                        if (this.timeFilter[1] != 0) {
                            return feature.properties.mag  >= this.m0 &&
                                   feature.properties.mag   < this.m1 &&
                                   feature.properties.time >= this.t0 &&
                                   feature.properties.time  < this.t1
                        } else {
                            return feature.properties.mag  >= this.m0 &&
                                   feature.properties.mag   < this.m1 &&
                                   feature.properties.time >= this.t0
                        }
                    })
                    .filter(feature => {
                        if (this.reviewStatusOption != 'all') {
                            return feature.properties.status.toLowerCase() === this.reviewStatusOption
                        } else {
                            return true
                        }
                    })
                    .sort(function (x, y) { return x.properties.time - y.properties.time; })

                let a1 = new Date().getTime()
                console.log('applyMagTimeFilterToEvents: ' + (a1 - a0) + 'ms')

                return returnVal
            },

            applyBothFiltersToEvents(eventFeatures){
                let toReturn = this.applyMagTimeFilterToEvents(eventFeatures)
                if (this.listFollowsMap) {
                    toReturn = this.applyBoundsFilterToEvents(toReturn)
                }
                return toReturn

            },

            shakemapExists: function(feature) {
                //console.log('shakemapExists')

                // lookup the index of this feature, -1 if it doesn't exist
                var index = this.shakemaps.findIndex(function(element, i) {
                    return element.feature.id === feature.id
                })

                if (index >= 0) {
                    return true
                } else {
                    return false
                }
            },

            toggleLabels: function(checked) {
                console.log('toggleLabels')
                console.log(checked)
                if (checked) {
                    this.labelsLayer.addTo(this.$refs.map.mapObject)
                } else {
                    this.labelsLayer.removeFrom(this.$refs.map.mapObject)
                }
            },

            toggleInfrared: function(checked) {
                console.log('toggleInfrared')
                console.log(checked)
                if (checked) {
                    this.infraredLayer.addTo(this.$refs.map.mapObject)
                } else {
                    this.infraredLayer.removeFrom(this.$refs.map.mapObject)
                }
            },

            toggleWeather: function(checked) {
                console.log('toggleWeather')
                console.log(checked)
                if (checked) {
                    this.weatherLayer.addTo(this.$refs.map.mapObject)
                } else {
                    this.weatherLayer.removeFrom(this.$refs.map.mapObject)
                }
            },

            toggleTrafficFlow: function(checked) {
                console.log('toggleTrafficFlow')
                console.log(checked)
                if (checked) {
                    this.trafficFlowLayer.addTo(this.$refs.map.mapObject)
                } else {
                    this.trafficFlowLayer.removeFrom(this.$refs.map.mapObject)
                }
            },

            toggleTrafficIncident: function(checked) {
                console.log('toggleTrafficIncident')
                console.log(checked)
                if (checked) {
                    this.trafficIncidentLayer.addTo(this.$refs.map.mapObject)
                } else {
                    this.trafficIncidentLayer.removeFrom(this.$refs.map.mapObject)
                }
            },

            toggleShakemap: function(feature, type, checked) {
                //console.log('toggleShakemap')

                // lookup the index of this feature, -1 if it doesn't exist
                var index = this.shakemaps.findIndex(function(element, i) {
                    return element.feature.id === feature.id
                })

                if (index >= 0) {

                    this.shakemaps[index][type]['checked'] = checked

                    if (checked) {
                        this.shakemaps[index][type]['layer'].addTo(this.$refs.map.mapObject)
                    } else {
                        this.shakemaps[index][type]['layer'].removeFrom(this.$refs.map.mapObject)
                    }

                } else {
                    console.log('shakemap does not exist')
                }
            },

            addShakemapLayer: function(feature, layer, type, checked) {
                //console.log('addShakemapLayer')

                // lookup the index of this feature, -1 if it doesn't exist
                var index = this.shakemaps.findIndex(function(element, i) {
                    return element.feature.id === feature.id
                })

                if (index >= 0) {

                    // assign the layer to the global, reactive object
                    this.shakemaps[index][type]['layer']   = layer

                    // add the layer to the map, and display if needed
                    this.toggleShakemap(feature, type, checked)

                } else {
                    console.log('shakemap does not exist')
                }

            },

            removeShakemaps: function() {
                //console.log('removeShakemaps')

                var shakemapsClone = [...this.shakemaps]

                for (let i = 0; i < shakemapsClone.length; i++) {
                    this.shakemapManager(shakemapsClone[i].feature, false)
                }
            },

            shakemapManager: function(feature, selected) {
                //console.log('shakemapManager')

                console.log('----- shakemapManager begin -----')
                console.log('shakemaps.length:' + this.shakemaps.length)
                console.log(this.shakemaps)

                // lookup the index of this feature, -1 if it doesn't exist
                var index = this.shakemaps.findIndex(function(element, i) {
                    return element.feature.id === feature.id
                })
                console.log('index:' + index)

                // remove from map and inventory
                if (!selected) {

                    if (index >= 0) {

                        // remove all from map
                        this.toggleShakemap(feature, 'intensity', false)
                        this.toggleShakemap(feature, 'stations', false)
                        this.toggleShakemap(feature, 'shapefile', false)

                        // remove shakemap object
                        this.shakemaps.splice(index, 1)

                        console.log('deleted shakemap ...')
                    }

                // add to map and inventory
                } else {

                    var shakemapObject, preferredShakemap, layer, url1, url2

                    // shakemap doesn't exist, and needs to be added
                    if (index < 0) {

                        // get the preferred shakemap info (this assumes this shakemap is from the selectedFeature)
                        preferredShakemap = this.sortShakemaps[0]['contents']

                        // initialize inventory
                        shakemapObject = {
                            feature: feature,
                            intensity: { checked: this.showShakemapIntensity },
                            stations:  { checked: this.showShakemapStations },
                            shapefile: { checked: this.showShakemapShapefile },
                        }

                        // add the shakemap object to the inventory
                        this.shakemaps.push(shakemapObject)


                        /****
                             Intensity: download intensity products and create layer
                        ****/
                        url1 = preferredShakemap['download/intensity_overlay.png']['url']
                        url2 = preferredShakemap['download/intensity_overlay.pngw']['url']
                        layer = new L.WorldFile( { imageUrl: url1, textUrl: url2, opacity: 0.5, })

                        // add the shakemap layer to the inventory
                        this.addShakemapLayer(feature, layer, 'intensity', this.showShakemapIntensity)


                        /****
                             Shapefile: download shapefile products
                        ****/
                        url1 = preferredShakemap['download/shape.zip']['url']
                        shp(url1)
                            .then(data => {

                                // filter out the 'mi' shapefile
                                var miData = data.filter(featureCollection => featureCollection.fileName === 'mi')

                                // create the shapefile layer
                                layer = new L.GeoJSON(miData, {
                                    onEachFeature: this.shakemapShapefileOnEachFeature,
                                    filter: this.shakemapShapefileFilter,
                                    style: this.shakemapShapefileStyle,
                                })

                                // add the shakemap layer to the inventory
                                this.addShakemapLayer(feature, layer, 'shapefile', this.showShakemapShapefile)
                            })

                        /****
                            Stations: download stationlist and create stations map layer
                        ****/
                        url1 = preferredShakemap['download/stationlist.json']['url']
                        axios
                            .get(url1)
                            .then(response => {
                                layer = new L.GeoJSON(response.data.features, {
                                    onEachFeature: this.shakemapStationsOnEachFeature,
                                    filter: this.shakemapStationsFilter,
                                })

                                // add the shakemap layer to the inventory
                                this.addShakemapLayer(feature, layer, 'stations', this.showShakemapStations)
                            })
                            .catch(error => {
                                console.log(error)
                                this.errored = true
                            })
                            .finally(() => this.loading = false)

                        // zoom to the feature that we have just added
                        this.panToFeature(feature)
                    }
                }

                console.log('shakemaps.length:' + this.shakemaps.length)
                console.log(this.shakemaps)
                console.log('----- shakemapManager end -----')
            },

            scheduleCatalogUpdate: function() {
                //console.log('scheduleCatalogUpdate')

                // clear the existing schedule
                clearInterval(this.catAutoUpdateHandler)

                if (this.catAutoUpdateOption > 0) {

                    // grab a new catalog
                    this.getCatFeatures()

                    // set the interval
                    var self = this
                    this.catAutoUpdateHandler = setInterval(function () {
                        self.getCatFeatures()
                    }, this.catAutoUpdateOption * 60 * 1000)
                    console.log('set catalog auto-update to ' + this.catAutoUpdateOption)
                }
            },

            // navigation functions

            openSelectedFeature: function(feature, fromUpdate=false) {
                console.log('openSelectedFeature')

                // make this the selected feature
                this.makeSelectedFeature(feature)

                // show the event detail pane
                if (!fromUpdate){
                    this.showEvent = true
                }

                // zoom to the event on the map
                this.panToFeature(feature)

                // Checks if the map container size changed and updates the map if so
                const vm = this
                setTimeout(function() { vm.$refs.map.mapObject.invalidateSize() }, 100);
            },

            makeSelectedFeature: function(feature) {
                console.log('makeSelectedFeature')

                // assign this to the global object
                this.selectedFeature = feature

                // download the GeoJson detail
                this.getDetailedFeature(this.selectedFeature)

                this.scrollToFeature(this.selectedFeature)

                // reset the event detail to the event info tab
                this.tabIndex = 0
            },

            setHoveredFeature: function(feature){
                console.log("set hoveredFeature")
                this.hoveredFeature = feature
            },

            // table functions

            listSortChange: function(value) {
                console.log('listSortChange')

                const params = value.split('-')

                this.listSortField = "properties." + params[0]

                if (params[1] == "asc") {
                    this.listSortDescending = false
                } else {
                    this.listSortDescending = true
                }
            },

            lookupTableIndex: function(feature) {
                console.log('lookupTableIndex')
                const sortedItemsList = this.$refs.eventList.sortedItems

                var index = sortedItemsList.findIndex(function(element, i) {
                    return element.id === feature.id
                })
                return index
            },

            scrollToFeature: function(feature){
                this.tableIndex = this.lookupTableIndex(feature)
                if (this.tableIndex!=-1){
                    this.currentPage = Math.floor(this.tableIndex/this.perPage)+1
                    const currentPageIndex = this.tableIndex % this.perPage
                    
                    this.$refs.eventList.clearSelected()
                    this.$refs.eventList.selectRow(currentPageIndex)
                    this.justChangedPage = true
                }
            },

            tableRowClicked: function(features) {
                console.log('tableRowClicked')

                // if this was an actual selection, and not a 'fake' selection (sorting, etc)
                if (features.length > 0) {

                    // make this the selected feature
                    this.openSelectedFeature(features[0])
                }
            },

            tableRowVariant: function(item, type) {
                if (!item || type !== 'row') return

                let toReturn;
                if (item.dataSource === 'rt') {
                    toReturn = 'border border-2 border-' + this.getFeatureVariant(item) + ' table-' + this.getFeatureVariant(item)
                } else {
                    toReturn =  'table-' + this.getFeatureVariant(item)
                }
                if (this.isHoveredFeature(item)){
                    toReturn += ' hoveredRow'
                }
                return toReturn
            },

            getFeatureVariant: function (feature) {

                if (feature.id === this.selectedFeature.id) {
                    return 'info'

                } else if (feature.properties.status === 'reviewed') {
                    return 'light'

                } else if (feature.properties.status === 'automatic') {
                    return 'secondary'

                } else {
                    return 'secondary'
                }

            },

            changeSelectedShakemap: function(index,ref){
                this.selectedShakemap = index;
            },

            changeAllowedDYFI: function(index,ref){
                this.allowedDYFI = index;
            },

            changeSelectedBeachball: function(index, ref){
                this.allowedBeachBall = index
            },

            changeAllowedPAGER: function(index,ref){
                this.allowedPAGER = index;
            },

            changeSelectedRow: function(index, table_id){
                var tableRows = document.getElementById(table_id).getElementsByTagName("tbody")[0];

                var toRemoveClass = tableRows.children;
                [].forEach.call(toRemoveClass, function(el) {
                    el.classList.remove("table-info");
                });

                document.getElementById(table_id).getElementsByTagName("tbody")[0].childNodes[index].classList.add("table-info")
            },

            // map functions

            initMap: function() {
                console.log('initMap')

                var authOptions = {
                    authType: "anonymous",
                    clientId: mapCid,
                    getToken: function(resolve, reject, map) {
                        fetch(mapAuth).then(function(response) {
                            return response.text()
                        }).then(function(token) {
                            resolve(token)
                        })
                    }
                }


                // instantiate the MS Azure Maps base layers
                this.baseLayerLight = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.base.road',
                    language: 'en-US',
                    pane: 'tilePane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })

                this.baseLayerDark = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.base.darkgrey',
                    language: 'en-US',
                    pane: 'tilePane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })

                this.baseLayerImagery = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.imagery',
                    language: 'en-US',
                    pane: 'tilePane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })

                // instantiate the MS Azure Maps overlays
                this.labelsLayer = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.base.hybrid.road',
                    language: 'en-US',
                    pane: 'overlayPane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })

                this.infraredLayer = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.weather.infrared.main',
                    language: 'en-US',
                    pane: 'overlayPane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })

                this.weatherLayer = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.weather.radar.main',
                    language: 'en-US',
                    pane: 'overlayPane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })

                this.trafficFlowLayer = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.traffic.flow.relative',
                    language: 'en-US',
                    pane: 'overlayPane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })

                this.trafficIncidentLayer = aml.L.tileLayer.azureMaps({
                    authOptions: authOptions,
                    tilesetId: 'microsoft.traffic.incident.s1',
                    language: 'en-US',
                    pane: 'overlayPane',
                }).on('tileerror', function(tile, error) {
                    console.log(error)
                })


                // create the base layers array
                this.baseLayers = [
                    { item: 0, name: 'Light', layer: this.baseLayerLight, },
                    { item: 1, name: 'Dark', layer: this.baseLayerDark, },
                    { item: 2, name: 'Imagery', layer: this.baseLayerImagery, },
                ],


                // this makes the 'light' layer on by default
                console.log('setting the default map layer ...')
                this.baseLayers[this.layerIndex].layer.addTo(this.$refs.map.mapObject);

                console.log('creating the mouse position control ...')
                L.control.mousePosition({
                    position: 'bottomright',
                    emptyString: '0º N : 0º E',
                    separator: ' : ',
                    lngFormatter: function(num) {
                        var direction = (num < 0) ? 'W' : 'E';
                        var formatted = Math.abs(L.Util.formatNum(num, 3)) + 'º ' + direction;
                        return formatted;
                    },
                    latFormatter: function(num) {
                        var direction = (num < 0) ? 'S' : 'N';
                        var formatted = Math.abs(L.Util.formatNum(num, 3)) + 'º ' + direction;
                        return formatted;
                    }
                }).addTo(this.$refs.map.mapObject)
            },

            panToFeature: function(feature) {

                // pan to the event on the map
                this.$refs.map.mapObject.panTo(L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]))
            },

            mapFeatureClicked: function(feature) {
                //console.log('mapFeatureClicked')

                // make this the selected feature
                //this.makeSelectedFeature(feature)
                this.openSelectedFeature(feature)
            },

            mapPresetClicked: function(preset) {
                //console.log('mapPresetClicked')

                this.$refs.map.mapObject.setView(L.latLng(preset.lat, preset.lon), preset.zoom)
                //this.$refs.map.mapObject.flyTo(L.latLng(preset.lat, preset.lon), preset.zoom)
            },

            updateMapBounds: function() {
                console.log('updateMapBounds')

                // only update the bounds if the map is displayed, otherwise bounds become 0, and no EQs will be shown in list
                if (this.showMap) {
                    this.bounds = this.$refs.map.mapObject.getBounds()
                    console.log(this.bounds._southWest.lat + '|' +
                                this.bounds._northEast.lat + '|' +
                                this.bounds._southWest.lng + '|' +
                                this.bounds._northEast.lng)
                    console.log('zoom:' + this.$refs.map.mapObject._zoom)
                }
            },

            getFeatureCoords: function(feature, offset) {
                var lat = feature.geometry.coordinates[1]
                var lng = feature.geometry.coordinates[0]
                if (offset === 'left') {
                    lng -= 360
                } else if (offset === 'right') {
                    lng += 360
                }
                return [lat, lng]
            },

            getFeatureZIndex: function(feature) {
                //console.log('getFeatureZIndex')

                if (feature.id === this.selectedFeature.id) {
                    return (this.maxEventTime - this.minEventTime + 1001)
                } else {
                    return (feature.properties.time - this.minEventTime + 1000)
                }
            },

            getCircleMarkerColor: function(feature) {
                return '#000000'
            },

            getCircleMarkerFillColor: function(feature, getClassVersion=false) {
                var color

                let selected = getClassVersion ? "selected-icon": "#008080"
                let red = getClassVersion ? "red-icon" : "#ff0000"
                let blue = getClassVersion ? "blue-icon" : "#0000ff" 
                let yellow = getClassVersion ? "yellow-icon" : "#ffff00"
                let orange = getClassVersion ? "orange-icon": "#ffa500"
                let transparent = getClassVersion ? "transparent-icon" : "#ffffff"

                if (feature.id === this.selectedFeature.id && this.markerDecoration === "highlight"){
                    color = selected
                    return color
                }

                let ageInHours = ( this.now - feature.properties.time ) / ( 1000 * 60 * 60 )
                if (this.showClassicColors) {
                    color = (ageInHours <=   1) ? red
                            : (ageInHours <=  24) ? blue
                            : (ageInHours <= 168) ? yellow
                            :                       transparent
                } else {
                    color = (ageInHours <=   1) ? red
                            : (ageInHours <=  24) ? orange
                            : (ageInHours <= 168) ? yellow
                            :                       transparent
                }
            

                return color
            },


            getFeatureSize: function(mag) {
                //console.log('getFeatureSize')

                // group magnitudes for simplicity
                if (mag < 1) mag = 0
                if (mag > 7) mag = 7

                // floor bins group .00 to .99 together
                mag = Math.floor(mag)
                //console.log(mag)

                // scale the size appropriately
                //mag = Math.pow(mag, 1.6) + 4
                mag = Math.pow(mag, 1.5) + 6

                return mag
            },

            getFeatureStyle: function(value) {
                let size = this.getFeatureSize(value)
                let shape = '50%/50%'
                if (this.showSquareMarkers) shape = ''
                return {
                    height: size + 'px',
                    width: size + 'px',
                    margin: '0 auto',
                    border: '1px solid rgba(0,0,0,0.5)',
                    borderRadius: shape,
                }
            },

            getFeatureBorder: function(feature){
                let toReturn
                if (this.markerDecoration =="bold" && this.isSelectedFeature(feature)){
                    toReturn = "selected-border" 
                } else {
                    if (this.isHoveredFeature(feature)){
                        toReturn = "hovered-border"
                    } else {
                        toReturn = "icon-border"
                    }
                }
                return toReturn
            },

            isSelectedFeature: function(feature){
                return this.selectedFeature.id === feature.id
            },

            isHoveredFeature: function(feature){
                return this.hoveredFeature?.id === feature.id
            },

            getFeatureIcon: function(feature) {
                //console.log('getFeatureIcon')

                let size = this.getFeatureSize(feature.properties.mag)
                let colorClass = this.getCircleMarkerFillColor(feature, true)
                let shapeClass = "circle-icon"
                let borderClass = this.getFeatureBorder(feature)
                if (this.showSquareMarkers)  shapeClass = ""

                var parameters = {
                    iconSize: [size, size],
                    className: `${borderClass} ${colorClass} ${shapeClass}`,
                }

                if (this.isSelectedFeature(feature) && this.markerDecoration == 'cross'){
                    parameters.iconUrl = "./img/cross.png"
                    return L.icon(parameters)
                } else {
                    return L.divIcon(parameters)
                }   
            },

            getVolcanoIcon: function(feature) {

                var alertLevel, iconUrl, w, h

                alertLevel = feature.properties.alertLevel.toLowerCase()

                if (alertLevel == "normal") {
                    iconUrl = "./img/green_normal.png"
                    //w = 21
                    //h = 17
                    w = 15.75
                    h = 12.75
                } else if (alertLevel == "advisory") {
                    iconUrl = "./img/yellow_advisory.png"
                    //w = 27
                    //h = 22
                    w = 20.25
                    h = 16.5
                } else if (alertLevel == "watch") {
                    iconUrl = "./img/orange_watch.png"
                    //w = 33
                    //h = 25
                    w = 24.75
                    h = 18.75
                } else if (alertLevel == "warning") {
                    iconUrl = "./img/red_warning.png"
                    //w = 38
                    //h = 30
                    w = 28.5
                    h = 22.5
                } else {
                    iconUrl = "./img/unassigned_unassigned.png"
                    //w = 18
                    //h = 14
                    w = 13.5
                    h = 10.5
                }

                return L.icon({
                    iconSize: [w, h],
                    iconUrl: iconUrl,
                })
            },

            closeMapPresets: function() {
                this.$refs.mapPresets.hide(true)
            },

            closeMapLegend: function() {
                this.$refs.mapLegend.hide(true)
            },

            // real-time functions

            notifyFeature: function(feature) {
                //console.log('notifyFeature')

                // get the current notification settings
                let nt0 = Date.now() + (this.rtNotifTimeFilter[0] * 3600000)
                let nm0 = this.rtNotifMagFilter[0]
                //console.log('nm0:' + nm0 + ' | nt0:' + nt0 + ' ' + this.formatDate(nt0))

                if (feature.properties.time > nt0 && feature.properties.mag > nm0) {

                    console.log('notification criteria met ...')

                    if (this.showRTNotifToast) {
                        this.rtNotifToast(feature)
                    }
                    if (this.showRTNotifScreenFlash || this.showRTNotifAudioAlarm) {
                        this.rtNotifFlashBeep()
                    }
                    if (this.panToNewEvents && this.applyBothFiltersToEvents([feature]).length){
                        console.log("opened new rt feature")
                        this.openSelectedFeature(feature, true)
                    }
                }
            },

            //cookies
            initAllowCookies: function(){

                if ($cookies.isKey("allowCookies")) {
                    this.allowCookies= $cookies.get("allowCookies") == 'true' ? true : false;
                    if (this.allowCookies){
                        this.cookieModal=false;
                    }
                }
            },

            acceptCookies: function(){
                $cookies.set("allowCookies", "true")
                this.allowCookies= true;
            },

            rejectCookies: function(){
            },

            unInitCookies: function(){
                $cookies.keys().forEach(cookie => $cookies.remove(cookie));
            },

            initCookies: function(){

                $cookies.config('1000d')
                this.initAllowCookies();

                // columns
                this.initShowEventCookie();
                this.initShowMapCookie();
                this.initShowListCookie();
                this.initShowSettingsCookie();
                this.initShowHelpCookie();

                // settings
                this.initPanToNewEventsCookie();
                this.initShowLocalTimeCookie();
                this.initShowMilesCookie();
                this.initWrappedMarkersCookie();
                this.initMarkerDecorationCookie();
                this.initShowRTNotifToastCookie();
                this.initShowRTNotifScreenFlashCookie();
                this.initShowRTNotifAudioAlarmCookie();
                this.initCatAutoUpdateOptionCookie();
                this.initReviewStatusOptionCookie();
                this.initDataSourceOptionCookie();
                this.initMagFilterCookie();
                this.initTimeFilterCookie();
                this.initAutoHideDelayOptionCookie();
                this.initNotifMagFilterCookie();
                this.initNotifTimeFilterCookie();

                // map
                this.initCenterCookie();
                this.initZoomCookie();
                this.initLayerIndexCookie();

                this.initShowEarthquakesCookie();
                this.initShowVolcanoesCookie();
                this.initShowFaultsCookie();
                this.initShowPlateBoundariesCookie();
                this.initShowUsStatesCookie();
                this.initShowUsCountiesCookie();
                this.initShowLabelsCookie();
                this.initShowTrafficFlowCookie();
                this.initShowTrafficIncidentCookie();
                this.initShowInfraredCookie();
                this.initShowWeatherCookie();
                this.initShowSquareMarkersCookie();
                this.initShowClassicColorsCookie();

                // list
                this.initListSortOptionCookie();
                this.initListFollowsMapCookie();
            },

            resetSettings: function(){
                //Object.assign(this.$data, this.$options.data.apply(this))
                //this causes problems making it so you can only have one column at a time
                Object.assign(this.$data, this.initialSettingsState());
                this.$refs.map.mapObject.setView(this.center, this.zoom)
            },

            initialSettingsState(){
                  return {
                    //Map
                    zoom: 5,
                    center: L.latLng(36, -120),
                    layerIndex: 0,
                    showEarthquakes: true,
                    showVolcanoes: false,
                    showPlateBoundaries: false,
                    showUsFaults: false,
                    showUsStates: true,
                    showUsCounties: true,
                    showLabels: false,
                    showInfrared: false,
                    showWeather: false,
                    showTrafficFlow: false,
                    showTrafficIncident: false,
                    showSquareMarkers: false,
                    showClassicColors: false,

                    //settings vars
                    panToNewEvents: false,
                    showLocalTime: false,
                    showMiles: false,
                    wrappedMarkers: false,
                    markerDecoration: 'bold',
                    showRTNotifToast: true,
                    showRTNotifScreenFlash: false,
                    showRTNotifAudioAlarm: false,

                    catAutoUpdateOption: 5,
                    reviewStatusOption: 'all',
                    magFilter: [2.5,10],
                    timeFilter: [-1,0],

                    rtNotifAutoHideDelayOption: 30000,
                    rtNotifMagFilter: [4,10],
                    rtNotifTimeFilter: [-4,0],

                    //list cookies
                    listFollowsMap: true,
                    listSortOption: "time-desc",
                  }
            },

            // column cookies

            initShowMapCookie: function(){
                if ($cookies.isKey("showMap")) {
                    this.showMap= $cookies.get("showMap") == 'true' ? true : false;
                }
            },

            initShowEventCookie: function(){
                if ($cookies.isKey("showEvent")) {
                    this.showEvent= $cookies.get("showEvent") == 'true' ? true : false;
                }
            },

            initShowListCookie: function(){
                if ($cookies.isKey("showList")) {
                    this.showList= $cookies.get("showList") == 'true' ? true : false;
                }
            },

            initShowSettingsCookie: function(){
                if ($cookies.isKey("showSettings")) {
                    this.showSettings= $cookies.get("showSettings") == 'true' ? true : false;
                }
            },

            initShowHelpCookie: function(){
                if ($cookies.isKey("showHelp")) {
                    this.showHelp= $cookies.get("showHelp") == 'true' ? true : false;
                    if (this.showHelp){
                        this.showStates = $cookies.get("showStates").split(",").map(Number).map(Boolean);
                    }
                }
            },


            // settings cookies

            initPanToNewEventsCookie: function(){
                if ($cookies.isKey("panToNewEvents")) {
                    this.panToNewEvents= $cookies.get("panToNewEvents")  == 'true' ? true : false;
                }
            },

            initShowLocalTimeCookie: function(){
                if ($cookies.isKey("showLocalTime")) {
                    this.showLocalTime= $cookies.get("showLocalTime")  == 'true' ? true : false;
                }
            },

            initShowMilesCookie: function(){
                if ($cookies.isKey("showMiles")) {
                    this.showMiles= $cookies.get("showMiles")  == 'true' ? true : false;
                }
            },

            initWrappedMarkersCookie: function(){
                if ($cookies.isKey("wrappedMarkers")) {
                    this.wrappedMarkers= $cookies.get("wrappedMarkers")  == 'true' ? true : false;
                }
            },

            initMarkerDecorationCookie: function(){
                if ($cookies.isKey("markerDecoration")) {
                    this.markerDecoration = $cookies.get("markerDecoration")
                }
            },

            initShowRTNotifToastCookie: function(){
                if ($cookies.isKey("showRTNotifToast")) {
                    this.showRTNotifToast= $cookies.get("showRTNotifToast") == 'true' ? true : false;
                }
            },

            initShowRTNotifScreenFlashCookie: function(){
                if ($cookies.isKey("showRTNotifScreenFlash")) {
                    this.showRTNotifScreenFlash= $cookies.get("showRTNotifScreenFlash") == 'true' ? true : false;
                }
            },

            initShowRTNotifAudioAlarmCookie: function(){
                if ($cookies.isKey("showRTNotifAudioAlarm")) {
                    this.showRTNotifAudioAlarm= $cookies.get("showRTNotifAudioAlarm") == 'true' ? true : false;
                }
            },

            initCatAutoUpdateOptionCookie: function(){
                if ($cookies.isKey("catAutoUpdateOption")) {
                     this.catAutoUpdateOption= $cookies.get("catAutoUpdateOption");
                }
            },

            initReviewStatusOptionCookie: function(){
                if ($cookies.isKey("reviewStatusOption")) {
                    this.reviewStatusOption= $cookies.get("reviewStatusOption");
                }
            },

            initDataSourceOptionCookie: function(){
                if ($cookies.isKey("dataSourceOption")) {
                    this.dataSourceOption= $cookies.get("dataSourceOption");
                }
            },

            initMagFilterCookie: function(){
                if ($cookies.isKey("magFilter")) {
                    let cookieArray=$cookies.get("magFilter").split(",").map(Number)
                     this.magFilter = cookieArray
                }
            },

            initTimeFilterCookie: function(){
                if ($cookies.isKey("timeFilter")) {
                    let cookieArray=$cookies.get("timeFilter").split(",").map(Number)
                    this.timeFilter = cookieArray
                }
            },

            initAutoHideDelayOptionCookie: function(){
                if ($cookies.isKey("rtNotifAutoHideDelayOption")) {
                    this.rtNotifAutoHideDelayOption= $cookies.get("rtNotifAutoHideDelayOption");
                }
            },

            initNotifMagFilterCookie: function(){
                if ($cookies.isKey("rtNotifMagFilter")) {
                    this.rtNotifMagFilter = $cookies.get("rtNotifMagFilter").split(",").map(Number);
                }
            },

            initNotifTimeFilterCookie: function(){
                if ($cookies.isKey("rtNotifTimeFilter")) {
                    this.rtNotifTimeFilter = $cookies.get("rtNotifTimeFilter").split(",").map(Number);
                }
            },

            //map cookies

            initLayerIndexCookie: function(){
                if ($cookies.isKey("layerIndex")) {
                    this.layerIndex= parseFloat($cookies.get("layerIndex"));
                }
            },

            initShowEarthquakesCookie: function(){
                if ($cookies.isKey("showEarthquakes")) {
                    this.showEarthquakes= $cookies.get("showEarthquakes") == 'true' ? true : false;
                }
            },

            initShowVolcanoesCookie: function(){
                if ($cookies.isKey("showVolcanoes")) {
                    this.showVolcanoes= $cookies.get("showVolcanoes") == 'true' ? true : false;
                }
            },

            initShowFaultsCookie: function(){
                if ($cookies.isKey("showUsFaults")) {
                    this.showUsFaults= $cookies.get("showUsFaults") == 'true' ? true : false;
                }
            },

            initShowPlateBoundariesCookie: function(){
                if ($cookies.isKey("showPlateBoundaries")) {
                    this.showPlateBoundaries= $cookies.get("showPlateBoundaries") == 'true' ? true : false;
                }
            },

            initShowUsStatesCookie: function(){
                if ($cookies.isKey("showUsStates")) {
                    this.showUsStates= $cookies.get("showUsStates") == 'true' ? true : false;
                }
            },

            initShowUsCountiesCookie: function(){
                if ($cookies.isKey("showUsCounties")) {
                    this.showUsCounties= $cookies.get("showUsCounties") == 'true' ? true : false;
                }
            },

            initShowLabelsCookie: function(){
                if ($cookies.isKey("showLabels")) {
                    this.showLabels= $cookies.get("showLabels") == 'true' ? true : false;
                }
            },

            initShowTrafficFlowCookie: function(){
                if ($cookies.isKey("showTrafficFlow")) {
                    this.showTrafficFlow= $cookies.get("showTrafficFlow") == 'true' ? true : false;
                }
            },


            initShowTrafficIncidentCookie: function(){
                if ($cookies.isKey("showTrafficIncident")) {
                    this.showTrafficIncident= $cookies.get("showTrafficIncident") == 'true' ? true : false;
                }
            },


            initShowInfraredCookie: function(){
                if ($cookies.isKey("showInfrared")) {
                    this.showInfrared= $cookies.get("showInfrared") == 'true' ? true : false;
                }
            },

            initShowWeatherCookie: function(){
                if ($cookies.isKey("showWeather")) {
                    this.showWeather=$cookies.get("showWeather") == 'true' ? true : false;
                }
            },


            initShowClassicColorsCookie: function(){
                if ($cookies.isKey("showClassicColors")) {
                    this.showClassicColors= $cookies.get("showClassicColors") == 'true' ? true : false;
                }
            },

            initShowSquareMarkersCookie: function(){
                if ($cookies.isKey("showSquareMarkers")) {
                    this.showSquareMarkers= $cookies.get("showSquareMarkers") == 'true' ? true : false;
                }
            },


            initCenterCookie: function(){
                if ($cookies.isKey("center")) {
                    let coordinates=$cookies.get("center").split(",").map(Number)
                    this.center= L.latLng(coordinates[0], coordinates[1])
                }
            },

            setCenterCookie (center){
                if (this.allowCookies){
                    $cookies.set("center", [center.lat, center.lng])
                }
            },

            initZoomCookie: function(){
                if ($cookies.isKey("zoom")) {
                    this.zoom= parseFloat($cookies.get("zoom"))
                }
            },

            setZoomCookie(zoom){
                if (this.allowCookies){
                    $cookies.set("zoom", zoom)
                }
            },


            //list cookies

            initListFollowsMapCookie: function(){
                if ($cookies.isKey("listFollowsMap")) {
                    this.listFollowsMap= $cookies.get("listFollowsMap") == 'true' ? true : false;
                }
            },

            initListSortOptionCookie: function(){
                if ($cookies.isKey("listSortOption")) {
                    this.listSortOption= $cookies.get("listSortOption");
                    this.listSortChange(this.listSortOption);
                }
            },

            // this is called from getCatFeatures, since we need to wait for that to return, 
            // when the catalog is updated
            initSelectedFeatureCookie: function(){
                console.log('initSelectedFeatureCookie')

                if ($cookies.isKey("selectedFeatureID")) {
                    var selectedFeatureID = $cookies.get("selectedFeatureID")
                    console.log('selectedFeatureID:' + selectedFeatureID)

                    // lookup the selectedFeature in the updated catalog
                    var index = this.catFeatures.findIndex(function(element, i) {
                        return element.id == selectedFeatureID
                    })

                    // if the feature exists in the catalog
                    if (index >= 0) { 

                        // selectedFeature doesn't exist, fetch the details
                        if (Object.keys(this.selectedFeature).length === 0) {
                            console.log('initializing selectedFeature with details')
                            setTimeout(()=>this.makeSelectedFeature(this.catFeatures[index]), 0)

                        // selectedFeatures does exist, check if needs updating
                        } else {

                            console.log('comparing selectedFeature to catFeature')
                        
                            // check if the catalog feature is newer than the selectedFeature
                            var selectedFeatureUpdated, catFeatureUpdated
                            selectedFeatureUpdated = this.selectedFeature.properties.updated
                            catFeatureUpdated      = this.catFeatures[index].properties.updated

                            //console.log('selectedFeatureUpdated:' + selectedFeatureUpdated)
                            //console.log(this.selectedFeature)
                            //console.log('catFeatureUpdated:' + catFeatureUpdated)
                            //console.log(this.catFeatures[index])

                            // if catalog feature is newer, request the details
                            if (catFeatureUpdated > selectedFeatureUpdated) {
                                console.log('catFeature is newer than selectedFeature, fetching details')
                                setTimeout(()=>this.makeSelectedFeature(this.catFeatures[index]), 0)
                            }
                        }
                    }
                }
            },

            rtNotifToast: function (feature) {
                //console.log('rtNotifToast')

                console.log('creating toast ...')

                // create the notification content
                const rtNotifVariant = this.getFeatureVariant(feature)
                const rtNotifTitle   = "New event popup"
                const rtNotifMsg     = this.$createElement('div',
                    {
                        on: {
                            click:() => {
                                this.openSelectedFeature(feature)
                                this.$bvToast.hide(feature.id)
                            }
                        }
                    },
                    [
                        'M ' + this.formatMag(feature.properties.mag) + ' - ' + this.formatPlace(feature.properties.place),
                        this.$createElement('br'),
                        this.formatDate(feature.properties.time),
                        this.$createElement('br'),
                        this.formatEventID(feature.id) + ' (' + this.formatStatus(feature.properties.status) + ')',
                    ]
                )

                this.rtNotifNoAutoHide = false
                if (this.rtNotifAutoHideDelayOption == 0) this.rtNotifNoAutoHide = true

                // display the notification
                this.$bvToast.toast(
                    [rtNotifMsg],
                    {
                        id: feature.id,
                        title: `${rtNotifTitle}`,
                        appendToast: true,
                        toaster: 'b-toaster-top-left',
                        variant: rtNotifVariant,
                        autoHideDelay: this.rtNotifAutoHideDelayOption,
                        noAutoHide: this.rtNotifNoAutoHide,
                    }
                )
                console.log('toast sent ...')
            },

            rtNotifFlashBeep: function() {
                console.log('rtNotifFlashBeep')

                // about 0.5 seconds total, but 0.4 might be more appropriate

                var screen = document.getElementById("fullscreen")

                var snd = new Audio(rtNotifAudioFile);

                var times    = this.rtNotifNumFlashes
                var interval = this.rtNotifFlashDuration * 2
                var uptime   = this.rtNotifFlashDuration
                var stopped  = false

                const vm = this

                window.addEventListener("click", function() {
                    stopped = true;
                    if (vm.showRTNotifScreenFlash) {
                        screen.classList.remove("fullscreen-alerted");
                    }
                }, { once: true })

                for (var i = 0; i < times; i++) {

                    setTimeout(function () {
                        if (stopped == false) {
                            if (vm.showRTNotifAudioAlarm) {
                                snd.currentTime = 0
                                snd.play()
                                .catch(function(error) {
                                    console.log(error)
                                });
                            }
                            if (vm.showRTNotifScreenFlash) {
                                screen.classList.add("fullscreen-alerted")
                            }
                        }
                    }, i * interval)

                    setTimeout(function() { 
                        if (vm.showRTNotifScreenFlash) {
                            screen.classList.remove("fullscreen-alerted")
                        }
                    }, (i * interval) + uptime)
                }
            },

            signalrStart: function() {
                //console.log('signalrStart')

                const vm = this

                // create the signalr connection
                console.log('creating the signalr connection ...');
                console.log(signalrAuth)
                this.signalrConnection = new signalr.HubConnectionBuilder()
                    .withUrl(signalrAuth)
                    .withAutomaticReconnect()
                    .configureLogging(signalr.LogLevel.Information)
                    .build();

                // start the signalr connection
                console.log('connecting to signalr ...')
                this.signalrConnection.start()
                    .then((response) => {
                        console.log('signalr connected')
                    })
                    .catch(error => {
                        console.log(error)
                    })

                // start listening for new messages
                this.signalrConnection.on('newMessage', function(message) {
                    vm.getRtFeatures(message)
                })

            },

            signalrStop: function() {
                //console.log('signalrStop')

                // start the signalr connection
                console.log('disconnecting from signalr ...')
                this.signalrConnection.stop()
                    .then((response) => {
                        console.log('signalr disconnected')
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            async signIn() {
                //console.log('signIn')

                const vm = this
                await this.$msal.loginPopup(loginRequest)
                    .then(function (loginResponse) {
                        vm.displayName = loginResponse.account.name
                        vm.isAuthenticated = true
                        vm.signalrStart()
                    }).catch(function (error) {
                        console.log(error)
                    })
            },

            async signOut() {
                //console.log('signOut')

                const vm = this
                await this.$msal.logoutPopup({})
                    .then(function (logoutResponse) {
                        vm.displayName = ''
                        vm.isAuthenticated = false
                        vm.signalrStop()
                    }).catch(function (error) {
                        console.log(error)
                    })
            },

            getTokenPopup: function(request) {
                //console.log('getTokenPopup')

                request.account = this.$msal.getAccountByUsername
                const vm = this
                return this.$msal.acquireTokenSilent(request)
                    .catch(error => {
                        console.warn('silent token acquisition fails.  acquiring token using popup')
                        if (error instanceof msal.InteractionRequiredAuthError) {
                            return vm.$msal.acquireTokenPopup(request) 
                                .then(tokenResponse => {
                                    console.log(tokenResponse)
                                    return tokenResponse
                                }).catch(error => {
                                    console.error(error)
                                });
                        } else {
                            console.warn(error)
                        }
                    });
            },

            initAuth: function() {
                console.log('initAuth')

                const currentAccounts = this.$msal.getAllAccounts()
                //console.log(this.$msal)

                if (currentAccounts === null) {
                    return
                } else if (currentAccounts.length >= 1) {
                    console.log('current account found, accounts:' + currentAccounts.length)
                    //console.log('currentAccount', currentAccounts[0])
                    this.displayName = currentAccounts[0].name
                    this.isAuthenticated = true
                    this.signalrStart()
                }
            },
        },

        mounted: function() {
            //console.log('mounted ...')
            window.addEventListener('resize', this.handleResize)
            this.handleResize()

            // init the retries for api calls
            axiosRetry(axios, { retries: 3 })

            // init the bounds of the existing screen size for the event list
            this.bounds = this.$refs.map.mapObject.getBounds()

            // retrieve the event data in geojson format
            console.log('retrieving event data ...')
            console.log(eventAPI)
            this.getCatFeatures()

            this.scheduleCatalogUpdate()

            // prepare the map tiles
            console.log('initializing the map ...')
            this.initMap()

            // retrieve the volcano data in geojson format
            console.log('retrieving volcano data ...');
            axios
                .get(volcanoAPI)
                .then(response => {
                    console.log('retrieved volcano data ... ' + response.data.features.length);
                    this.volcanoes = response.data.features
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)

            setTimeout(() => this.mousewheelScrollsDropdown(), 100)

            

        },

        //async created: function() {
        async created() {
            console.log('creating Quake Display ...')

            this.initAuth()


            // update 'now' on a timer, and thus everything that depends on it (mostly icon colors)
            var self = this
            setInterval(function () {
                console.log('updating now ...')
                self.now = new Date().getTime()
            }, this.autoUpdateInterval)

            this.initCookies();

        },

        destroyed() {
            console.log('destroying Quake Display ...')

            window.removeEventListener('resize', this.handleResize)

        },
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

    .scroll-class {
        overflow-y: scroll;
    }

    /*
    If showMap variable is true, set the settings, detail, and list columns to the fixed side class.  Else, set to flex.
    This is decided by a ternary operator using `:class="[showMap ? 'fixed-side' : 'flexed']"` on the b-column of that column.
    If the min-width of the page is above 1000px, set the columns to the max width of 500px
    */

    .flexed {
        flex:1;
    }

    .fixed-side {
        -ms-flex: 0 0 400px;
        flex: 0 0 400px;
    }

    /* These make it so no x-scroll on the event-detail by making it so long words are broken,
    and that the little bit left over is not considered */

    div#eventDetail, div#list {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    div#settings {
        overflow-x: hidden;
    }

    div#eventDetail div.tab-content {
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    /* the actual content is the full amount, not just the browser, and said content scrolls */
    div#eventDetail div.tab-pane, div#eventTablePageWrapper {
        height: 100%;
        overflow-y: auto;
    }

    table#eventList {
        display: block;
    }

    table#eventList thead, table#eventList tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    table#eventList tr td {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .border-2 {
        border-width: 2px !important;
    }

    .border-3 {
        border-width: 3px !important;
    }

    .navbar-light .navbar-nav .nav-link.active {
        color: teal;
    }

    .nav-tabs {
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .nav-tabs .nav-link.active {
        color: teal;
    }

    .nav-tabs li > a {
        color: black;
    }

    .leaflet-top {
        z-index: 800;
    }

    .dropdown-content {
        background: rgba(248,248,248,0.9);
    }

    .dropdown-content .btn {
        background: rgba(104,104,104,0.5);
    }

    .dropdown-content .btn:hover {
        background: rgba(248,248,248,0.5);
    }

    .dropdown-content .btn:active {
        background: rgba(248,248,248,0.5);
    }

    .dropdown-content .btn:disabled {
        background: rgba(104,104,104,0.5);
    }

    .legend .dropdown-header {
        padding: 0;
    }

    .overlay-legend {
        list-style: none;
        padding-left: 50px;
        padding-right: 50px;
    }

    .overlay-legend div {
        border: 1px solid #ccc;
        float: right;
        width: 60px;
        height: 4px;
        margin-top: 9px;
     }

    .overlay-legend svg {
        float: right;
        margin-top: 6px;
     }

    .page-link {
        color: black;
        background-color: #fdfdfe;
    }

    .page-link:hover {
        color: black;
    }

    .page-item.active .page-link {
        color: black;
        background-color: #d6d8db;
        border-color: black;
    }

    div.transparent {
        background-color: transparent;
    }

    .icon-border {
        border: 1px solid rgba(0,0,0,0.5);
    }

    .selected-border {
        border: 1px solid rgba(0,0,0,0.5);
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 50%;
            outline: solid 2px black;
        } 
    }

    /*needed to remove position: relative here for some reason*/
    .hovered-border {
        border: 1px solid #ececf6;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 50%;
            outline: solid 2px #ececf6;
        } 
    }

    .transparent-icon.legend-cross-background{
        background-image: url("/img/cross.png");
        background-position: center;
    }

    .circle-icon {
        border-radius: 50%/50%;
    }

    .selected-icon {
        background: rgb(14, 109, 109);
    }

    .transparent-icon {
        background: transparent;
    }

    .red-icon {
        background: red;
    }

    .orange-icon {
        background: orange;
    }

    .yellow-icon {
        background: yellow;
    }

    .blue-icon {
        background: blue;
    }

    .green-color {
        color: green;
    }

    .red-color {
        color: red;
    }

    .orange-color {
        color: orange;
    }

    .teal-color {
        color: teal;
    }

    /* settingsc css. negative margin values are to counteract parent margins */
    .settings-header {
        background-color: #767676;
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: -.5rem;
        margin-right: -.5rem;
    }

    .settings-header-text {
        color: white;
        padding-left: .5em;
        padding-top: .5em;
        padding-bottom: .5em;
        font-size: 1rem;
    }

    .settings-slider-section {
        padding-bottom: 2em;
    }

    .tooltip {
        font-size: 16px;
    }

    tr.detail-table-rows {
        white-space:nowrap;
        cursor: pointer;
    }

    tr.detail-table-rows:first-child td:first-child::after {
        color: #090;
        content: '   ✓';
    }

    tr.detail-table-rows:hover{
         background-color: #c8cbcf;
    }

    table tr.table-info:hover,table tr.table-info:hover>td {
        background-color: #abdde5;
    }

    .hoveredRow{
        &.table-info > td{
            background-color: #abdde5 !important;
        }
        &.table-secondary > td{
            background-color: #c8cbcf !important;
        }
        &.table-light > td{
            background-color: #ececf6 !important;
        }
    }

    .hoveredRow.table-secondary{
        background-color: #c8cbcf !important;

    }

    span.carousel-control-next-icon, span.carousel-control-prev-icon{
        color: 	gray;
        background-color: 	gray;
    }

    .event-info-wrapper {
        margin: 0 0 0 0;
        max-width: 100%;
    }

    .event-info-table tbody {
        display: block;
        line-height: 16px;
    }

    .event-info-table td:nth-child(2) {
        width: 100%;
    }

    .event-info-table td:first-child {
        max-width: 150px;
        min-width: 100px;
        width: 150px;
        font-weight: bold;
    }

    .cookie-modal.modal-body {
        height: 170px;
    }

    .cookie-button {
        /*background-color: #abdde5;*/
        position: relative;
        top:70px;
    }

    #cookie-buttons {
        float: right;
        position: relative;
        width: 7%;
        min-width: 100px;
        display: inline-block;
    }

    #cookie-text {
        margin: auto;
        position: absolute;
        width: 60%;
        text-align: center;
    }

    #cookie-close {
        color: rgba(0,0,0,.5);
        font-size: 125%;
        float: right;
        position: relative;
        bottom:10px;
    }

    .leaflet-container .leaflet-control-mouseposition {
        box-shadow: 0 0 5px #bbb;
        padding: 0 5px;
        margin: 0;
        color: #333;
        background-color: rgba(255, 255, 255, 0.7);
        font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    }

    #fullscreen{
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        height:100%;
        width:100%;
        pointer-events: none;
        z-index: 10000;
    }

    .fullscreen-alerted{
        background-color:red;
        opacity: 0.5;
    }

    div#mapLayers ul.dropdown-menu, div#mapShakemaps ul.dropdown-menu {
        overflow-y: scroll;
        max-height: 400px;
    }

    .switch-container .b-dropdown-form, .switch-container {
        display: flex;
        justify-content: space-between;
        & .custom-control {
            margin-left: 5px
        }
    }

    .btn-group-xs > .btn, .btn-xs {
        padding: .5rem .5rem;
        font-size: .875rem;
        line-height: .5;
        border-radius: .2rem;
    }

    #resetToDefault {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    a {
        color: #0070f4;
    }

    .contrib-header {
        font-size: 18px; 
        text-decoration: underline
    }

    .no-wrapping > th{
        white-space: nowrap
    }

    /* @media screen and (min-width: 1000px){
        .logo {
            height: 70px;
        }
        #CGS-logo{
            padding: 8px;
        }
        .title-container {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 999px){
        .logo {
            height: 50px;
        }
        #CGS-logo{
            padding: 6px;
        }
        .title-container {
            display: none
        }
    } 
     @media screen and (max-width: 500px){
        .logo {
            height: 33px;
            padding: 0px 
        }
        #CGS-logo{
            padding: 4px;
        }
        .navbar-brand {
            margin-right: 0px
        }
    }
    */
        .logo {
            height: 29px;
            padding: 0px 
        }
        #CGS-logo{
            padding: 0px 8px /*so effects title in a similar visual way to the toggle icons*/
        }
        .navbar-brand {
            margin-right: 0px
        }
        .logo-container {
            display: flex;
            flex-direction: row;
        }
        .consistent-line-height{
            line-height: 1.15;
        }
        .title-container {
            width: 100%;
            display: flex;
            justify-content: center;
            container: title-container / inline-size
        }
        @container title-container (min-width: 0px){
            .title {
                margin-bottom: 0;
                font-size: min(1.5em, 18cqw);
            }
        }

        .authenticated-container{
            height: 40px;
            & a {
                padding:10px 0px !important;

            }
            & svg {
                vertical-align: middle !important;
            }
        }

    .title {
        color: teal
    }

    html {
        overflow-y: hidden
    }

/*
source below:
https://github.com/Office-of-Digital-Services/California-State-Web-Template-HTML/blob/fa8b031ec621a4623426ba9e5e04dda36c28aeac/ca_state_template/css/cagov.core.css#L17700 
*/
    
.utility-header {
  width: 100%;
  min-height: 42px;
  transition: all 0.3s ease;
  font: 1.125rem/1.5 "Public Sans",system-ui,-apple-system,"Segoe UI","Roboto","Helvetica Neue","Noto Sans",sans-serif;
}
.utility-header .half {
  position: relative;
  min-height: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
  float: unset;
  width: 100%;
  padding-top: 0;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .utility-header .half {
    float: left;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .utility-header .half {
    padding-top: 2px;
    float: left;
  }
  .utility-header .half:first-child {
    padding-left: 15px;
    padding-right: 0 !important;
    width: 50%;
  }
  .utility-header .half:nth-child(2) {
    padding-left: 0 !important;
    width: 50%;
  }
}
.utility-header a {
  display: inline-block;
  padding: 0;
  text-decoration: underline;
  color: var(--black, #000);
}
.utility-header a:hover, .utility-header a:focus, .utility-header a.hovered {
  text-decoration: none;
  color: var(--gray-800, #4a4958);
}
.utility-header ul {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
  margin-bottom: 0;
}
.utility-header ul > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.utility-header ul .list-inline-item {
  display: inline-block;
}
.utility-header ul .list-inline-item:not(:last-child) {
  margin-right: 5px;
}
.utility-header ul li {
  padding: 0 8px;
}
.utility-header ul li button {
  font-size: 100%;
  outline: none !important;
  position: relative;
  top: -1px;
  background-color: transparent;
  border: none;
}
.utility-header ul li button span {
  font-size: 100% !important;
}
.utility-header ul li button:hover, .utility-header ul li button:focus {
  color: var(--gray-800, #4a4958);
  background-color: transparent;
  border: none;
}
.utility-header .settings-links {
  font-size: 1rem;
  text-align: right;
}
@media (min-width: 576px) {
  .utility-header .settings-links {
    font-size: calc(1rem + 0.1vw);
  }
}
.utility-header [class^=ca-gov-icon-] {
  font-size: 1.1rem;
  vertical-align: -2px;
  display: inline-block;
  text-decoration: none;
  padding-right: 4px;
}
.utility-header .located-city-name {
  margin-left: 5px;
}
.utility-header .geo-zip-input {
  margin-left: 5px;
  width: 50%;
  vertical-align: initial;
  line-height: 120%;
  background: white;
  color: var(--black, #000);
}
.utility-header .flex-row {
  display: flex;
}
.utility-header .flex-row .settings-links {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
  min-height: 38px;
}
.utility-header .flex-row .settings-links a {
  margin: 0;
  margin-left: 1rem;
  font-size: 0.95rem;
}
.utility-header .flex-row .settings-links a:last-child {
  margin-right: 0;
}
.utility-header .flex-row .settings-links button {
  margin: 0;
  padding-right: 0;
  font-size: 0.95rem;
  color: var(--black, #000);
}
.utility-header .flex-row .settings-links button:hover, .utility-header .flex-row .settings-links button:focus {
  text-decoration: none;
  color: var(--gray-800, #4a4958);
}
.utility-header .flex-row .social-media-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 38px;
  align-items: center;
}
.utility-header .flex-row .social-media-links a {
  margin: 0 10px;
  color: var(--black, #000);
  text-decoration: none;
}
.utility-header .flex-row .social-media-links a:hover, .utility-header .flex-row .social-media-links a:focus {
  color: var(--gray-800, #4a4958);
}
.utility-header .flex-row .social-media-links .header-cagov-logo {
  margin-right: 0.5rem;
}
.utility-header .flex-row .social-media-links .header-cagov-logo img {
  left: 0;
  height: 24px;
  margin-top: 6px;
  vertical-align: middle;
}
.utility-header .flex-row .social-media-links .header-cagov-logo .ca-gov-logo-svg {
  vertical-align: middle;
}
.utility-header .flex-row .social-media-links .header-cagov-logo a {
  margin: 0;
  padding-top: 0;
}
.utility-header .flex-row .social-media-links .official-tag {
  margin: 0 1rem 0 0;
  font-size: 0.95rem;
}
@media (max-width: 767px) {
  .utility-header .flex-row .social-media-links .official-tag {
    margin-right: 0;
  }
  .utility-header .flex-row .social-media-links .official-tag .desktop-only {
    display: none;
  }
}

.header-cagov-logo svg {
  left: 27px;
  height: 1.7rem;
}
@media (max-width: 1199px) {
  .header-cagov-logo svg {
    left: 5px;
  }
}

/* Hide utilities in mobile */
@media (max-width: 991px) {
  button[aria-controls=siteSettings] {
    display: none;
  }
}
    
/* Oceanside Theme */
.utility-header {
    background: #046B99;
    color: #ffffff
}

.utility-header a {
    color: #ffffff
}

.utility-header a:hover,.utility-header a:focus,.utility-header a.hovered {
    text-decoration: none;
    color: #fff0cf
}

.utility-header ul li {
    padding: 0 8px
}

.utility-header ul li button:hover,.utility-header ul li button:focus {
    color: #fee09c;
    background-color: rgba(0,0,0,0);
    border: none
}

/* Other */
.ca-gov-logo-svg {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 1304 949'><path fill='%23fdb71c' d='M799 398c3-11 12-42 27-91 3-13 12-38 27-77 14-40 26-71 34-95-28 19-53 42-76 67-92 100-200 220-238 283 14-4 43-34 136-66 29-11 59-18 90-21zm-16 64c-291 0-418 464-620 464-46 0-81-21-106-62-16-27-25-58-24-90 0-84 40-191 120-320 68-110 140-198 216-265 65-58 121-86 168-86 24-1 46 10 61 29 12 16 18 35 17 54 0 38-13 81-38 130-22 42-50 80-83 113-23 21-40 31-52 31-9-1-17-5-22-12-5-5-8-13-8-20 0-14 12-29 36-47 31-23 58-51 80-82 29-43 44-80 44-110 1-1-2-19-8-27-6-6-15-9-24-9-22 0-52 16-92 47-53 43-101 91-143 144-59 69-108 146-146 228-34 78-52 144-52 199-1 24 8 48 23 67 14 20 37 32 62 31 92-3 207-214 243-263C787 120 814 119 867 68c31-30 55-44 71-44 10 0 20 5 26 12s9 16 10 25c-2 20-8 39-16 58-19 48-37 100-54 155-15 49-25 86-30 112 5 0 9 0 14-1 11 0 21-1 29-1 23 0 34 9 34 26 0 8-3 16-7 22s-10 11-17 13c-6 1-13 1-19 1-17-1-33 1-50 4-4 12-15 117-31 121-49 43-45-95-44-109z'/><path d='M495 779c12 0 24 5 33 14s14 20 14 33c0 26-21 46-46 46-26 0-46-21-46-46-1-26 19-47 45-47zm284-123-33 32c-18-21-44-33-72-33-22-1-44 8-60 24-16 15-25 36-24 58 0 22 9 44 25 59 16 16 39 25 62 24 14 0 28-3 40-10 12-8 21-19 26-31h-70v-43h122v10c0 21-6 42-17 60-10 18-25 33-43 44-19 10-40 16-62 15-24 0-47-5-67-16s-36-27-47-47-17-43-17-66c0-31 11-61 32-84 25-28 61-44 99-42 20 0 40 4 59 12 18 8 34 20 47 34zm159-45c33 0 65 13 89 37s38 57 37 91c1 34-12 66-36 90-50 49-130 49-179 0l-1-1c-49-50-49-131 1-180 23-24 55-37 89-37zm-1 45c-21 0-42 8-56 23-15 16-24 37-23 60-1 25 10 48 29 64 14 12 33 19 51 18 21 0 41-8 56-24 31-33 31-84 0-117-15-16-36-24-57-24zm118-36h47l61 172 62-172h47l-87 242h-45z' fill='%23003688'/></svg>");
    aspect-ratio: 33/24;
    width: 33px;
    display: inline-block
}

/* .utility-container  custom width  */
.utility-container  {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}
@media (min-width: 576px) {
  .utility-container  {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .utility-container  {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .utility-container  {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .utility-container  {
    max-width: 1176px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=block');

</style>
