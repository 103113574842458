import Vue from 'vue'
import VueRouter from 'vue-router'

import Quakewatch from '@/components/Quakewatch'
import Signin from '@/components/Signin'

Vue.use(VueRouter)

const routes = [
    { path: '/', component: Quakewatch },
    { path: '/signin', component: Signin },
    { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
