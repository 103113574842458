<template>

</template>

<script>

    import { loginRequest, tokenRequest } from '@/js/authConfig'

    export default {
        name: 'Signin',
        data () {
            return {
                isAuthenticated: true,
                displayName: null,
            }
        },

        methods: {

            async signIn() {
                //console.log('signIn')

                const vm = this
                await this.$msal.loginPopup(loginRequest)
                    .then(function (loginResponse) {
                        vm.displayName = loginResponse.account.name
                        vm.isAuthenticated = true
                        vm.$router.push('/')
                    }).catch(function (error) {
                        console.log(error)
                    })
            },
        },

        async created () {
            this.signIn()
        },
    }

</script>

<style scoped>

</style>
