import Vue from 'vue'
Vue.config.productionTip = false

import { default as msalPlugin } from 'vue-msal-browser'
import { msalConfig } from '@/js/authConfig'
Vue.use(msalPlugin, msalConfig)

import {BootstrapVue, BootstrapVueIcons, BVToastPlugin, DropdownPlugin, ModalPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(BVToastPlugin)
Vue.use(DropdownPlugin)
Vue.use(ModalPlugin)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
Vue.component('VueSlider', VueSlider)

import { LMap, LControl, LControlScale, LGeoJson, LCircleMarker, LMarker, LPopup, LTooltip } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
Vue.component('l-map', LMap)
Vue.component('l-control', LControl)
Vue.component('l-control-scale', LControlScale)
Vue.component('l-geo-json', LGeoJson)
Vue.component('l-circle-marker', LCircleMarker)
Vue.component('l-marker', LMarker)
Vue.component('l-popup', LPopup)
Vue.component('l-tooltip', LTooltip)

import App from './App.vue'
import router from './router'

const app = new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
